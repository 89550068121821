import { DefineComponent } from "vue";
import digits from "./Digits/Digits.vue";
import strings from "./Strings/Strings.vue";
import photos from "./Photos/Photos.vue";
import allphoto from "./AllPhoto/AllPhoto.vue";
import info from "./Info/Info.vue";
import byPhoto from "./ByPhoto/ByPhoto.vue";
import calendar from "./Calendar/Calendar.vue";
import maintext from "./MainText/MainText.vue";
import kitchen from "./Kitchen/Kitchen.vue";
import quote from "./Quote/Quote.vue";
import people from "./People/People.vue";
import take from "./Take/Take.vue";
import animals from "./Animals/Animals.vue";
import video from "./Video/Video.vue";

export const components: { [key: string]: DefineComponent<{}, {}, any> } = {
  digits,
  strings,
  photos,
  info,
  byPhoto,
  calendar,
  maintext,
  kitchen,
  quote,
  people,
  take,
  allphoto,
  animals,
  video,
};
