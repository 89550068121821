<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<style lang="scss" scoped>
main {
  height: 100%;
  box-sizing: border-box;
  background: url("@/share/assets/images/main_bg.svg") no-repeat center / cover;
  z-index: -2;
}
</style>
