<template>
  <router-link class="logo" :to="{ name: 'Home' }"></router-link>
</template>

<style lang="scss" scoped>
.logo {
  width: 28.6em;
  aspect-ratio: 20/9.1;
  display: flex;
  flex-wrap: wrap;
  background: url("@/share/assets/icons/logo.svg") no-repeat center / contain;
}
</style>
