export const people: iContent.Content = {
  section: "Люди Тувы",
  links: [],
  pages: {
    home: {
      info: [],
      subComponents: [
        {
          type: "quote",
          propsData: {
            data: [
              {
                title: ``,
                info: [
                  `"В тувинскую культуру я погружен моими родителями, главным образом, отцом. У нас в доме бывали художники, композиторы и поэты. Я видел, как пишутся сказки, создаются гравюры… Однако мне трудно выделить то, чем я обязан именно моим национальным корням. Понимаете, это традиции людей, живущих в суровых природных условиях. Я знаю многих русских и даже немцев, которые, приехав в Туву, переняли местную культуру без насилия над собой. Им хотелось знать язык - они выучили язык. Им хотелось научиться разделывать барана так, чтобы оставались лишь копыта, - они научились этому", - рассказал министр в интервью журналу "Вокруг Света", приуроченном к 176-летию РГО.`,
                ],
                image: { title: "", url: "" },
              },
            ],
          },
        },
        {
          type: "people",
          propsData: {
            data: [
              {
                title: "Шойгу Сергей Кужугетович",
                info: [`Секретарь Совета Безопасности Российской Федерации, Герой России, Президент Русского географического общества`],
                image: { url: "asset26.jpg" },
              },
              { title: "Монгуш Буян-Бадыргы", info: [`Государственный деятель, основатель Тувинской государственности.`], image: { url: "asset11.webp" } },
              {
                title: "Сафьянов Иннокентий Георгиевич",
                info: [`Один из основателей тувинской государственности, золотопромышленник.`],
                image: { url: "asset19.webp" },
              },
              {
                title: "Салчак Тока Калбак-Хорекович",
                info: [
                  `Государственный деятель, Генеральный секретарь ЦК ТНРП, I-ый секретарь обкома КПCC, Герой соц. труда, кавалер семи Орденов Ленина, Трудового Красного знамени и трех орденов Тувинской Народной Республики`,
                ],
                image: { url: "asset16.webp" },
              },
              {
                title: "Кечил-оол Тулуш Балданович",
                info: [`Герой Советского Союза, командир Тувинского добровольческого кавалерийского эскадрона.`],
                image: { url: "asset4.webp" },
              },
              {
                title: "Чургуй-оол Хомушку Намгаевич",
                info: [`Герой Советского Союза, тувинский доброволец-танкист Великой Отечественной войны. `],
                image: { url: "asset23.webp" },
              },
              {
                title: "Бухтуев Михаил Артемьевич",
                info: [`Герой Советского Союза, механик-водитель танка 15-й гвардейской танковой бригады 1-го гвардейского танкового корпуса 65-й армии 1-го Белорусского фронта, гвардии сержант.`],
                image: { url: "asset2.webp" },
              },
              {
                title: "Сарыг-оол Степан Агбаанович",
                info: [
                  `Прозаик, поэт, драматург, переводчик, Народный писатель Тувы, один из основоположников тувинской литературы, Член союза писателей ТНР и СССР, кавалер орденов Дружбы народов, Трудового Красного Знамени и «Знак Почета». `,
                ],
                image: { url: "asset17.webp" },
              },
              {
                title: "Кок-оол Виктор Шогжапович",
                info: [`Заслуженный артист России, Народный артист Тувы, классик тувинской драматургии, один из основателей театрального искусства Тувы, кавалер ордена «Знак Почета». `],
                image: { url: "asset5.webp" },
              },
              {
                title: "Мунзук Максим Монгужукович",
                info: [
                  `Народный артист России и Тувы, лауреат Государственной премии Тувинской АССР, кавалер ордена «Дружбы народов», один из основателей Тувинского театра, Почетный гражданин городов Дуарпеза во Франции, Арсеньева, Кызыла и Тандинского кожууна. `,
                ],
                image: { url: "asset13.webp" },
              },
              {
                title: "Кужугет Сереевич Шойгу",
                info: [`Советский партийный и государственный деятель, секретарь Тувинского обкома КПСС, первый заместитель председателя Совета министров Тувинской АССР. `],
                image: { url: "asset7.webp" },
              },
              {
                title: "Сат Шулуу Чыргал-оолович",
                info: [`Первый профессор Тувы, основатель тувинского литературного языка, Заслуженный деятель науки РСФСР, Отличник Народного Просвещения. `],
                image: { url: "asset18.webp" },
              },
              {
                title: "Оскал-оол Владимир Базыр-оолович",
                info: [`Основатель тувинского циркового искусства, Народный артист СССР, Заслуженный артист РСФСР, Народный артист Тувы, кавалер Ордена Ленина.`],
                image: { url: "asset14.webp" },
              },
              {
                title: "Тойбу-Хаа Хертек Коштаевич",
                info: [`Заслуженный художник РСФСР, Народный художник Тувы, основоположник современного камнерезного искусства.`],
                image: { url: "asset20.webp" },
              },
              { title: "Аракчаа Раиса Ажыевна", info: [`Первая женщина-камнерез, Заслуженный художник Тув. АССР.`], image: { url: "asset1.webp" } },
              {
                title: "Ланзы Сергей Кончукович",
                info: [`Первый профессиональный художник Тувы, Заслуженный деятель литературы и искусства Тувы, Заслуженный художник РСФСР и Тув. АССР.`],
                image: { url: "asset9.webp" },
              },
              { title: "Монгуш Черзи Холасалович", info: [`Основоположник тувинского камнерезного искусства.`], image: { url: "asset12.webp" } },
              {
                title: "Чыргал-оол Алексей Боктаевич",
                info: [`Первый профессиональный композитор, Заслуженный деятель искусств РСФСР, Народный артист Тув. АССР. `],
                image: { url: "asset24.webp" },
              },
              {
                title: "Кенин-Лопсан Монгуш Борахович",
                info: [
                  `Заслуженный работник культуры РСФСР и Тув. АССР, Народный писатель Тувы, доктор исторических наук, действительный член Нью-Йоркской академии наук, обладатель звания «Живое сокровище шаманизма» американского фонда шаманских исследований, пожизненный Президент тувинских шаманов, кавалер орденов «Дружба народов» и «Орден Республики».`,
                ],
                image: { url: "asset3.webp" },
              },
              {
                title: "Кюнзегеш Юрий Шойдакович",
                info: [`Народный писатель Республики Тыва, поэт, переводчик, прозаик, литературовед, заслуженный работник культуры Тувинской АССР, лауреат Государственной премии Республики Тыва.`],
                image: { url: "asset8.webp" },
              },
              {
                title: "Ховалыг Кайгал-оол Ким-оолович",
                info: [`Народный хоомейжи Республики Тыва, вокалист группы Хуун-Хуур-Ту. Заслуженный артист России. `],
                image: { url: "asset22.webp" },
              },
              {
                title: "Монгуш Андрей Алдын-оолович",
                info: [`Заслуженный артист Республики Тыва (2006), Народный хоомейжи Республики Тыва (2007). `],
                image: { url: "asset10.webp" },
              },
              {
                title: "Кошкендей Игорь Михайлович",
                info: [`Носитель и виртуоз уникального феномена — тувинского горлового пения хоомей. Народный хоомейжи Республики Тыва, обладатель латиноамериканской премии «Grammy».`],
                image: { url: "asset6.webp" },
              },
              { title: "Оюн Михаил Кара-оолович", info: [`2-кратный чемпион Европы и Мира, чемпион Паралимпиады в 2012 г. `], image: { url: "asset15.webp" } },
              {
                title: "Хертек Саян Калдар-оолович",
                info: [
                  `Российский самбист и дзюдоист. Трёхкратный чемпион мира по самбо (Сочи-2017, Бишкек-2022, Ереван-2023), многократный чемпион России по самбо, призёр чемпионата Европы по самбо, обладатель Кубка мира по самбо, призёр чемпионатов России по дзюдо.`,
                ],
                image: { url: "asset21.webp" },
              },
            ],
          },
        },
      ],
    },
  },
};
