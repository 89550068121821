<script lang="ts" setup>
import { useStore } from "@/entities";
import { onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { SectionsNavigator } from "@/widgets";

const route = useRoute();
const router = useRouter();
const store = useStore();

const loader = ref(true);

onBeforeMount(() => {
  const { name, page } = route.params;
  if (!name || typeof name !== "string" || !page || typeof page !== "string") return router.push({ name: "Home" });
  store
    .dispatch("content/GET_SECTION", name)
    .then((result) => {
      if (result.error) return router.push({ name: "Home" });
      loader.value = false;
    })
    .catch((error) => {
      router.push({ name: "Home" });
    });
});
</script>

<template>
  <div v-if="loader"></div>
  <div class="container" v-else>
    <SectionsNavigator />
    <router-view></router-view>
  </div>
</template>

<style lang="scss" scoped>
.container {
  padding: 1em 1.5em 1em 0em;
  height: 100%;
  display: flex;
  gap: 2em;
  background-color: rgba(220, 220, 220, 1);
  box-sizing: border-box;
}
</style>
