<script lang="ts" setup>
import { PropType } from "vue";
import { components } from "@/share/components";

const { maintext } = components;

defineProps({
  prop: {
    type: Object as PropType<{ title: string; info: string[], data: { title: string; url: string; prev?: string }[] }>,
    required: true,
  },
});
</script>

<template>
  <div class="video">
    <div class="video__min">
      <video v-for="(video, index) in prop.data" :key="index" controls
        :poster="video.prev ? require(`@/share/assets/images/${$route.params.name}/${$route.params.page}/${video.prev}`) : ''">
        <source :src="require(`@/share/assets/video/${video.url}`)" type="video/mp4" />
        <source />
        Your browser doesn't support HTML5 video tag.
      </video>
    </div>
    <div class="video__text" v-if="prop.info">
      <maintext :text="{ text: prop.info }" :style="{ height: '12em', hidden: 'hidden' }">
      </maintext>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video {
  padding: 2em;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  flex-direction: row;

  &__min {
    width: 45%;
    position: relative;
    aspect-ratio: 16/9;

    & video {
      cursor: pointer;
      width: 100%;
    }
  }

  &__text {
    display: flex;
    width: 45%;
  }
}
</style>
