export function formatData(events: { datetime: string; event: string; allmonth: boolean }[]) {
  const map = new Map<number, { event: string[] }>();
  for (let i = 0; i < events.length; i++) {
    const date = events[i].datetime.split("-");
    if (events[i].allmonth) {
      const dayCounts = new Date(parseInt(date[0]), parseInt(date[1]), 0).getDate() + 1;
      for (let j = 1; j < dayCounts; j++) {
        map.set(new Date(parseInt(date[0]), parseInt(date[1]) - 1, j).getTime(), { event: [events[i].event] });
      }
    } else {
      if (map.has(new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])).getTime())) {
        map.get(new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])).getTime())?.event.push(events[i].event);
      } else {
        map.set(new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])).getTime(), { event: [events[i].event] });
      }
    }
  }
  return map;
}
