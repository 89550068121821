<script lang="ts" setup>
import { useStore } from "@/entities";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const exist_links = ref<HTMLLinkElement[]>();

const links = computed(() => store.state.content.section.links);

function goBack(event: Event) {
  router.back();
}
</script>

<template>
  <aside class="aside">
    <router-link class="logo" :to="{ name: 'Home' }"></router-link>
    <nav class="aside__nav">
      <ul>
        <li v-for="(link, index) in links" :key="index">
          <router-link :to="{ name: 'Content', params: { page: link.name } }" active-class="active-link" ref="exist_links">{{ link.title }}</router-link>
        </li>
        <span id="bground" ref="bground"></span>
      </ul>
    </nav>
    <button :to="{ name: 'Home' }" class="aside__back" @click="goBack">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.52 40.89">
        <g id="Слой_1" data-name="Слой 1">
          <path
            class="cls-1"
            d="M35.09,14.68l-4.68,4.59L20.32,9.54,9.42,20.44l10.9,10.91L37,14.84C42.16,9.72,47.3,3.74,54.91,4.07A16,16,0,0,1,70.52,20.4,16.53,16.53,0,0,1,55,37a15.49,15.49,0,0,1-11.73-4.88l-6-6,4.64-4.75L46.43,26c2.46,2.48,4.52,4.33,8.51,4.21,5.88-.19,8.89-5,8.89-9.79,0-5.05-4.08-9.5-9-9.62-5.21-.12-8.95,4.45-13.94,9.65-6.63,6.92-13.74,13.47-20.45,20.45L0,20.44,20.44,0Z"
          />
        </g>
      </svg>
      <p>назад</p>
    </button>
  </aside>
</template>

<style lang="scss" scoped>
.aside {
  flex-basis: 27.5%;
  padding: 1em 0 5.5em 4em;
  display: flex;
  flex-direction: column;
  background: url("@/share/assets/images/main_bg.svg") no-repeat center / cover;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  gap: 1em;
  &-logo {
    margin-left: 0.9em;
    height: 20%;
  }
  &__nav {
    max-height: 90%;
    flex-grow: 1;
    & ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
      gap: 0.5em;
      & li {
        list-style-type: none;
        padding: 0 1em 0 0;
        z-index: 1;
        & a {
          padding: 0.5em 0.8em;
          display: block;
          width: 100%;
          text-decoration: none;
          color: rgba(255, 229, 181, 1);
          font-family: "TT_Travels_Next_Bold", sans-serif;
          font-size: 2em;
          border-top-left-radius: 0.8em;
          border-bottom-left-radius: 0.8em;
        }
      }
    }
  }
  &__back {
    padding: 0;
    outline: none;
    border: none;
    position: relative;
    margin-right: 1em;
    width: 10em;
    padding: 0em 1em;
    aspect-ratio: 3.5/1;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: flex-end;
    font-size: 2em;
    text-decoration: none;
    color: #fff;
    background: rgba(211, 130, 2, 1);
    border-radius: 0.8em;
    text-transform: uppercase;
    gap: 1em;
    font-weight: bold;
    letter-spacing: 0.1em;
    font-family: "TT_Travels_Next_Bold", sans-serif;
    & p {
      margin: 0;
    }
    & svg {
      left: 1em;
      position: absolute;
      display: block;
      width: 2.5em;
      align-self: center;
      justify-self: center;
      fill: #fff;
      animation: arrow 2s ease-in infinite;
    }
  }
}
.active-link {
  color: #000 !important;
  background-color: #fff;
}

.logo {
  width: 28.6em;
  aspect-ratio: 20/9.1;
  display: flex;
  flex-wrap: wrap;
  background: url("@/share/assets/icons/logo.svg") no-repeat center / contain;
}

#bground {
  position: absolute;
  display: block;
  width: 35.5em;
  background-color: #fff;
  border-radius: 0.8em;
  z-index: 0;
  transition: top 0.1s;
}
@keyframes arrow {
  0% {
    left: 1em;
  }
  15% {
    left: 1.5rem;
  }
  30% {
    left: 1em;
  }
  100% {
    left: 1em;
  }
}
</style>
