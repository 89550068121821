export const about: iContent.Content = {
  section: "О Туве",
  links: [
    {
      name: "home",
      title: "О Туве",
    },
    {
      name: "history",
      title: "История республики",
    },
    {
      name: "relief",
      title: "Особенности региона",
    },
    {
      name: "resources",
      title: "Природные ресурсы",
    },
    {
      title: "Золото скифов",
      name: "scythian",
    },
    {
      name: "center",
      title: "Скульптурный комплекс «Центр Азии»",
    },
    {
      name: "hunters",
      title: "Скульптурная композиция «Царская охота»",
    },
  ],
  pages: {
    home: {
      info: [
        `Республика Тыва – уникальный регион на юге Сибири, располагается в самом центре Азии. Земля бескрайних степей и бесконечных горных систем. Снежные вершины Саян, хребты Танну-Ола, Шапшальский и Сенгилен каменной стеной окружили республику, спрятав ее красоты и богатства. Трудно назвать другой регион России, который на сравнительно небольшой территории имел бы такое разнообразие природных условий: бескрайний океан тайги, мощь великого Енисея, аромат сибирского кедра и пихты, сухие степи, сотни озер и целебных источников.`,
      ],
      image: "map.svg",
      imageWidth: "25em",
      photos: [
        { title: "Буддийский молитвенный барабан _Мани-Хурту_", url: "asset1.webp" },
        { title: "Буддийский монастырь «Тубтен Шедруб Линг» (с тибетского — Обитель объяснения и практики учения Будды Шакьямуни). Проект Культурного Фонда им. Кужугета Шойгу.", url: "asset2.webp" },
        { title: "Буддийский монастырь «Тубтен Шедруб Линг» (с тибетского — Обитель объяснения и практики учения Будды Шакьямуни). Проект Культурного Фонда им. Кужугета Шойгу", url: "asset3.webp" },
        { title: "Главный буддийский храм Республики Тыва Цеченлинг", url: "asset4.webp" },
        { title: "Кызыльская епархия Русской Православной Церкви", url: "asset5.webp" },
        { title: "Национальный музей им. Алдан-Маадыр (60-ти богатырей) Республики Тыва", url: "asset6.webp" },
        { title: "Национальный музыкально-драматический театр Республики Тыва имени Виктора Кок-оола", url: "asset7.webp" },
        { title: "Обелиск «Центр Азии» — памятник в Кызыле, на берегу Енисея, один из символов Тувы и наиболее известный монумент города.", url: "asset8.webp" },
        { title: "Обелиск «Центр Азии» — памятник в Кызыле, на берегу Енисея, один из символов Тувы и наиболее известный монумент города", url: "asset9.webp" },
        { title: "Обелиск «Центр Азии» — памятник в Кызыле, на берегу Енисея, один из символов Тувы и наиболее известный монумент города_JPG", url: "asset10.webp" },
        { title: "Скульптурная композиция _Царская охота_ на набережной реки Енисей", url: "asset11.webp" },
        { title: "Статуя Будды у слияния двух рек Большой и малый Енисей", url: "asset12.webp" },
        { title: "Этнокультурный комплекс «Алдын-Булак» в переводе с тувинского «Золотой источник» — это целый «музей» кочевой культуры под открытым небом", url: "asset13.webp" },
      ],
      subComponents: [
        {
          type: "digits",
          propsData: {
            title: "Тува в цифрах",
            data: [
              {
                number: "1921",
                numerator: "год",
                desciption: "дата образования",
              },
              {
                number: "168 604",
                numerator: "км",
                pow: "2",
                desciption: "площадь территории",
              },
              {
                number: "337 271",
                numerator: "тыс.",
                desciption: "численность населения",
              },
            ],
          },
        },
      ],
    },
    history: {
      info: [
        `Освоение территории началось в каменном веке более 20 тыс. лет назад. В разное время здесь жили скифские племена, гунны, многочисленные тюркские племена, уйгуры, кыргызы, в I тысячелетии н.э. происходило становление тувинской национальности. На протяжении II тысячелетия н.э. тувинская земля входила в состав государственных образований чингизидов, джунгаров и маньчжуров. С VI века до середины IX века территория Тывы входила в состав Тюркского, Уйгурского и Киргизского каганатов; а с начала XIII века и до конца ХIV века завоевана монголо-татарами и была под властью Чингисхана. Последующие два века имела относительную самостоятельность, но с конца XVI века попала под власть северо-монгольских государств.`,
        `В ХVIII веке завоевана маньчжурами и входила в состав Цинской империи. Маньчжурское иго ликвидировано лишь в 1912 году.`,
        `В 1914 году нынешняя Тыва (Урянхайский край) приняла протекторат России. С 1921 года по 1944 год просуществовало первое независимое государство тувинского народа - Народная Республика Танна-Тува - вплоть до вхождения в состав Советского Союза. С 1944 года по 1961 год Тува являлась составной частью РСФСР на правах автономной области, после чего была преобразована в автономную республику. С декабря 1994 года - Республика Тыва.`,
      ],
      photos: [
        { title: "Группа членов правительства Тувинской Народной Республики, ЦК ТНРП и представители СССР. 1925-1928", url: "asset1.webp" },
        { title: "Образование в Тувинской Народной Республике", url: "asset2.webp" },
        { title: "Первые летчики Тувинской Народной Республики", url: "asset3.webp" },
        { title: "Подготовка национальных медицинских кадров в Туве 1944-1961 гг", url: "asset4.webp" },
        { title: "Прием пушнины в магазине райпо в поселке Федоровка.1942 г", url: "asset5.webp" },
        { title: "Салчак Тока зачитывает Декларацию о желании Тувинской Народной Республики войти в состав СССР. 1944 год", url: "asset6.webp" },
        { title: "Строительство Национального музыкально-драматического театра Республики Тыва", url: "asset7.webp" },
        { title: "Танкисты-добровольцы с инструктором. Начало 1943 г.", url: "asset8.webp" },
        { title: "Тува за 100 лет_page-0007", url: "asset9.webp" },
        { title: "Члены Правительства Тувинской Народной Республики в 1925 году", url: "asset10.webp" },
        { title: "Ю. Никулин и В. Оскал-оол с группой тувинских артистов цирка. Год не указан. Из книги _История Тувы_, том III", url: "asset11.webp" },
      ],
      subComponents: [
        {
          type: "strings",
          propsData: {
            title: "Тува в цифрах",
            data: [
              {
                title: "Столица",
                desciption: "город Кызыл",
              },
              {
                title: "4668 км",
                desciption: "расстояние от Кызыла до Москвы",
              },
              {
                title: "+4 часа",
                desciption: "разница во времени с Москвой",
              },
            ],
          },
        },
      ],
    },
    relief: {
      info: [],
      subComponents: [
        {
          type: "byPhoto",
          propsData: {
            data: [
              {
                info: [
                  `Тыва расположена в Центральной Азии, на юге Восточной Сибири, в верховьях реки Енисей. В городе Кызыле - столице республики - географическая точка Центра Азии. По югу и юго-востоку - государственная граница с Монголией (протяженность 1371,2 км), на востоке регион граничит с Республикой Бурятия, на северо-востоке - с Иркутской областью, на западе - с Республикой Алтай, на северо-западе и севере - с Республикой Хакасия и Красноярским краем.`,
                ],
                image: { title: "Верблюдов разводят в Эрзинском районе Республики Тыва", url: "asset1.webp" },
              },
              {
                info: [
                  `Тыва - горная республика с чередованием высоких хребтов и глубоких котловин. Горы занимают 82% территории республики, равнинные участки - 18%. Сельскохозяйственные угодья - около 3,6 млн. га (21,5% территории).`,
                ],
                image: { title: "Висячий мост через реку Хемчик с. Шанчы Чаа-Хольского района", url: "asset2.webp" },
              },
              {
                info: [
                  `Основные природно-климатические зоны: тундровые плоскогорья с вечной мерзлотой, таежные массивы, соединяющиеся со степью и пустыней. В рельефе западной и центральной части республики выделяется Тувинская котловина, окруженная хребтами Западного Саяна, Шапшальским, Цаган-Шибэту, Танну-Ола и горами восточной Тувы. К юго-западу от хребта Цаган-Шибэту располагается наиболее высокий в Тувы горный массив Монгун-Тайга (3970 м). В пределах восточной, наиболее приподнятой части, находятся юго-западные склоны Восточного Саяна, Тоджинская котловина, Восточно-Тувинское нагорье с хребтом Академика Обручева и нагорье Сенгилен.`,
                ],
                image: { title: "Маралы в Пий-Хемском районе Республики Тыва", url: "asset3.webp" },
              },
            ],
          },
        },
      ],
    },
    resources: {
      info: [
        `Республика Тыва - уникальная металлогеническая провинция. На её территории - около 2 тысяч месторождений цветных и редких металлов, в том числе два десятка крупномасштабных объектов дефицитного минерального сырья: кобальта, лития, карбонатитов, танталниобитов. `,
        `Наиболее крупные месторождения - Каа-Хемский разрез (каменный уголь), Ак-Довуракское (хризотил-асбест), Хову-Аксынское (никель-кобальтовые руды), Тарданское (золотодобыча), Ак-Сугское (медь, молибден, золото, серебро), Кызыл-Даштыгское (свинец, цинк), Улуг-Танзекское (редкие металлы и редкоземельные элементы), Баян-Кольское месторождение уртитов. `,
        `Разведано более 20 крупных месторождений кирпичных глин, песчано-гравийной смеси, песков строительных для силикатных изделий, мраморированных известняков и мраморов. `,
        `Первозданная природа, большое количество природных и археологических памятников, обширные охотничьи угодья — всё это создает основу для развития российского и международного туризма в регионе.  `,
        `Основные туристические достопримечательности и культурно-этнографические объекты: Убса-Нурский биосферный заповедник; Курган Аржаан (самый древний скифский памятник); останки Уйгурских крепостей; Верхне-Чаданское Хурээ; Орхоно-енисейская письменность (около 150 камней с письменами); Скалы-верблюды; Дорога Чингисхана; Краеведческий музей им. «60 Богатырей»; Буддийские монастыри (хурээ). Популярны туристические маршруты к географическому центру Азии (г. Кызыл), по горным озерам, в особенности на озеро Тоджа. Крупные курорты: бальнеологический - Уш-Бельдир; грязевой - Чедер.`,
      ],
      direction: "column",
      photos: [
        { title: "Монгун-Тайга (3976 м) — высшая точка Восточной Сибири и одна из наиболее привлекательных для туристов гор Тувы.", url: "asset4.webp" },
        { title: "Монгун-Тайга (3976 м) — высшая точка Восточной Сибири и одна из наиболее привлекательных для туристов гор Тувы", url: "asset5.webp" },
        { title: "Песчаные дюны в сумоне Ийме Дзун-Хемчикского района", url: "asset6.webp" },
        { title: "Республика Тыва..", url: "asset7.webp" },
        { title: "Республика Тыва.", url: "asset8.webp" },
        { title: "Республика Тыва", url: "asset9.webp" },
      ],
      subComponents: [],
    },

    scythian: {
      info: [
        `В начале 2000-х годов археологи преподнесли миру настоящую сенсацию, которая в корне перевернула всеобщее представление о скифской цивилизации, её истории. На территории Тувы были раскопаны два кургана «Аржаан-1» и «Аржаан-2», расположенные на Турано-Уюкской котловине в Туве. Из-за обилия курганов эта территория была названа Долиной царей. `,
        `Курган «Аржаан-1» расположен недалеко от поселка Аржаан и был исследован в 70-х годах ХХ века. Археологи полагают, что захоронение было создано в IX-VIII веках до нашей эры. В кургане диаметром 120 метров было найдено центральное захоронение, по-видимому, принадлежащее представителям знати, и еще 70 погребений. Несмотря на то, что курган был частично разграблен в древности, здесь были обнаружены предметы быта и украшения с характерными для скифской культуры элементами звериного стиля.`,
        `Курган «Аржаан-2», расположенный неподалеку, был исследован в ходе совместной российско-германской археологической экспедиции в 2000-2003 годах, а также с участием волонтеров в последующие годы. Захоронение было датировано учеными VII веком до нашей эры. Несмотря на то, что курган в древности посещали искатели кладов, его все же можно назвать практически нетронутым. Здесь были найдены останки людей, по всей вероятности, принадлежавшие скифской знати, а также лошадей.`,
        `Обнаруженные в этом кургане золотые украшения, оружие, одежда и предметы быта были названы одной из главных находок в мировой археологии XXI века - из него было извлечено более 20 кг золота высокой 990 пробы, а точнее украшений и атрибутов из этого металла (около 9 тыс. предметов). Их реставрацией занимались сотрудники Государственного Эрмитажа, а также некоторых европейских центров.`,
        `Уникальные сокровища заняли подобающее им место в Национальном музее Тувы. Золото скифов хранится в специальной комнате-сейфе под усиленной охраной. Однако увидеть его может каждый. Для этого достаточно купить билет в музей.`,
      ],
      height: "16em",
      subComponents: [
        {
          type: "photos",
          propsData: {
            title: "",
            data: [
              { title: "", url: "asset1.webp" },
              { title: "", url: "asset2.webp" },
              { title: "", url: "asset3.webp" },
              { title: "", url: "asset4.webp" },
              { title: "", url: "asset5.webp" },
              { title: "", url: "asset6.webp" },
              { title: "", url: "asset7.webp" },
              { title: "", url: "asset8.webp" },
              { title: "", url: "asset9.webp" },
              { title: "", url: "asset10.webp" },
              { title: "", url: "asset11.webp" },
              { title: "", url: "asset12.webp" },
              { title: "", url: "asset13.webp" },
              { title: "", url: "asset14.webp" },
              { title: "", url: "asset15.webp" },
            ],
          },
        },
      ],
    },
    hunters: {
      info: [
        `Рядом с обелиском «Центр Азии» расположилась еще одна скульптурная композиция под названием «Царская охота», автором которой является Даши Намдаков, известный современный российский скульптор, график и ювелир, член Союза художников РФ.`,
        `Скульптурная композиция «Царская охота» выполнена в скифских мотивах, прототипом ее стали предметы, найденные при раскопке курганов, которые находятся в Республике Тыва, а именно, останки царя и царицы и хорошо сохранившая атрибутика царской власти.`,
        `Композиция представляет собой эпизод соколиной охоты двух всадников, мужчины и женщины. Быстрота, мощь оружия и ясный взор дают понять, что зверь не убежит от преследователей. Они властелины степей, где дом - шатер, а крыша дома - небо голубое.`,
        `Скульптура отображает, какими они были - кочевники и предки тувинского народа. Автор знакомит нас с культурой этого народа, погружая зрителя в мифический древний ритуал охоты.`,
        `«Царская охота» - это скульптура, рядом с которой понимаешь все величие и могущество степного народа.`,
      ],
      height: "12em",
      subComponents: [
        {
          type: "photos",
          propsData: {
            title: "",
            data: [
              { title: "", url: "asset1.webp" },
              { title: "", url: "asset2.webp" },
              { title: "", url: "asset3.webp" },
              { title: "", url: "asset4.webp" },
            ],
          },
        },
      ],
    },
    center: {
      info: [],
      photos: [
        { title: `Обелиск "Центр Азии". Фотография Вяеслава Зеленина`, url: "asset1.webp" },
        { title: `Обелиск "Центр Азии". Фотография Вяеслава Зеленина`, url: "asset2.webp" },
        { title: `Обелиск "Центр Азии". Фотография Вяеслава Зеленина`, url: "asset3.webp" },
        { title: `Обелиск "Центр Азии". Фотография Вяеслава Зеленина`, url: "asset4.webp" },
        { title: `Обелиск "Центр Азии". Фотография Вяеслава Зеленина`, url: "asset5.webp" },
        { title: `Обелиск "Центр Азии". Фотография Вяеслава Зеленина`, url: "asset6.webp" },
      ],
      subComponents: [
        {
          type: "byPhoto",
          propsData: {
            title: "",
            data: [
              {
                title: "Скульптурный комплекс «Центр Азии»",
                info: [
                  `Обелиск, символизирующий центр Азии, расположился на берегу Енисея, в городе Кызыле Республики Тыва. Его возводили три раза – в 1964, 1984 и 2014 году. Сегодняшний монумент – это целый скульптурный ансамбль.`,
                ],
                image: { title: `Обелиск "Центр Азии". Фотография Вяеслава Зеленина`, url: "asset5.webp" },
              },
              {
                title: `Первый памятник центру Азии`,
                info: [
                  `Впервые информация о том, что центр Азии находится именно в Кызыле, появилась в трудах инженера путей сообщения, исследователя Верхнего Енисея Всеволода Родичева. В 1910 году он опубликовал книгу «Очерк Урянхайского края (Монгольского бассейна реки Енисей)», в которой значилось: «В 90-х годах, однако, приехал в Урянхай английский путешественник со специальной целью видеть центр Азии, который, по его определению, пришелся возле усадьбы Г.П. Сафьянова Салдам, на Енисее. Столб в садике усадьбы знаменует этот примечательный пункт. Англичанин, по его словам, уже посетил центры Европы, Африки и Австралии». Однако этот знаменательный столб до наших дней не сохранился: скорее всего, памятник унесло паводком.`,
                ],
                image: { title: "Центр Азии", url: "asset7.webp" },
              },
              {
                title: `Карта Тувы из драгоценных камней`,
                info: [
                  `Немного восточнее того места, где был прежний памятник, в 1964 году поставили обелиск. Его открытие приурочили к 20-летию вхождения Тувы в состав Советского Союза (до 1944 года республика была независимым государством). Автором обелиска «Центр Азии» стал скульптор Василий Демин. На квадратном постаменте он установил глобус из бетона-монолита, пронзенный трехгранным шпилем. Карта Тувы на нем была выложена драгоценными и полудрагоценными камнями, а центр Азии отмечен позолоченным камнем. Высота всей композиции 12 метров.`,
                  `Через 20 лет по проекту архитектора Михаила Куулара памятник реконструировали. Композиция осталась прежней, но сам обелиск облицевали гранитом. Вокруг расчистили территорию, устроили береговую смотровую площадку и лестничный спуск к Енисею.`,
                ],
                image: { title: `Обелиск "Центр Азии". Фотография Вяеслава Зеленина`, url: "asset3.webp" },
              },
              {
                title: `Новый обелиск – в духе ранней буддийской культуры`,
                info: [
                  `В 2014 году построили новый обелиск, в честь 100-летия единения Тувы и России. Автором проекта стал бурятский художник, архитектор Даши Намдаков. Новый обелиск представляет собой ансамбль из трех львов – арзыланов. Они держат спинами земной шар, увенчанный шпилем. Шар декорировали фигурами восточных драконов, а высокую стелу – барельефами, которые изображают символических животных.`,
                ],
                image: { title: `Обелиск "Центр Азии". Фотография Вяеслава Зеленина`, url: "asset1.webp" },
              },
            ],
          },
        },
      ],
    },
  },
};
