import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { key, router, store } from "@/entities";
import { UIComponents } from "@/share/UI";

const app = createApp(App);

for (let i = 0; i < UIComponents.length; i++) {
  if (UIComponents[i].__file) {
    const path = UIComponents[i].__file!.split("/");
    app.component(path[path.length - 1].split(".")[0], UIComponents[i]);
  }
}

app.use(store, key).use(router).mount("#app");
