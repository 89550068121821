<script lang="ts" setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import HomeLayout from "@/layouts/HomeLayout.vue";
import ContentLayout from "@/layouts/ContentLayout.vue";
import { ScreenSaver } from "@/widgets";

const route = useRoute();
const layouts = {
  home: HomeLayout,
  content: ContentLayout,
};

const componentId = computed(() => route.meta.layout || "home");
</script>

<template>
  <component :is="layouts[componentId]"></component>
  <!-- <ScreenSaver /> -->
</template>

<style lang="scss">
html,
body {
  height: 100%;
  overflow: hidden;
}
#app {
  height: 100%;
}
</style>
