<script lang="ts" setup>
import { PropType, ref } from "vue";

const _span = ref<HTMLSpanElement>();

const props = defineProps({
  info: {
    type: Object as PropType<{ number: string; numerator: string; desciption: string; pow?: string }>,
    required: true,
  },
});

const _number = ref<string>("0");

function encreaseNumber(number: string) {
  const parsed = parseInt(number.replace(/\s/, ""));
  let _num = parseInt(_number.value.replace(/\s/, ""));
  if (isNaN(parsed) || isNaN(_num)) return;
  _num = parseInt(`1${"0".repeat(parsed.toString().length - 1)}`);
  let interval = setInterval(() => {
    const num = Math.round(parsed * 0.05);
    if (parsed < _num + Math.round(parsed * 0.05)) {
      if (_span.value) _span.value.style.opacity = "1";
      _number.value = parsed.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
      return clearInterval(interval);
    } else {
      _number.value = (_num += num).toString().replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
    }
  }, 100);
}
encreaseNumber(props.info.number);
</script>

<template>
  <div class="digit__widget">
    <h1 class="digit__widget-title">
      {{ _number }}
      <span ref="_span">
        {{ info.numerator }}
        <sup v-if="info.pow">
          {{ info.pow }}
        </sup>
      </span>
    </h1>
    <p>{{ info.desciption }}</p>
  </div>
</template>

<style lang="scss" scoped>
.digit__widget {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  aspect-ratio: 2/1;
  & h1 {
    width: max-content;
    position: relative;
    display: inline;
    align-items: flex-start;
    font-size: 3em;
    box-sizing: border-box;
    & span {
      position: absolute;
      top: 50%;
      right: -3em;
      opacity: 0;
      transition: opacity 0.3s;
      font-size: 0.4em;
    }
  }
  & p {
    margin: 0;
    font-size: 1.6em;
  }
}
</style>
