<script lang="ts" setup>
import { PropType, computed, ref } from "vue";

const props = defineProps({
  prop: {
    type: Object as PropType<{ title: string; data: { title: string; info: string[]; image: { title: string; url: string } }[] }>,
    required: true,
  },
});

const activeContentIndex = ref(0);

const content = computed(() => props.prop.data[activeContentIndex.value]);
</script>

<template>
  <div class="kitchen">
    <h1>{{ content.title }}</h1>
    <div class="kitchen-wrp">
      <div class="kitchen__content">
        <div class="kitchen__content-text">
          <p v-for="(text, index) in content.info" :key="index">{{ text }}</p>
        </div>
        <div class="kitchen__content-image">
          <img :src="require(`@/share/assets/images/${$route.params.name}/${$route.params.page}/${content.image.url}`)" alt="img" />
          <p>{{ content.image.title }}</p>
        </div>
      </div>
      <div class="kitchen__nav">
        <button v-for="(info, index) in prop.data" :key="index" @click="activeContentIndex = index" :class="{ kitchen__navActive: index === activeContentIndex }">{{ info.title }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.kitchen {
  display: flex;
  flex-direction: column;
  height: 100%;
  & h1 {
    text-align: center;
    margin: 0.2em;
  }
  &-wrp {
    display: flex;
    gap: 2em;
    height: 100%;
  }
  &__content {
    font-size: 1.5em;
    flex-basis: 75%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1.5em;
    &-text {
      height: 45%;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 1.5em;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track {
        background-color: #efefef;
        border-radius: 0.8em;
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        margin: 10em 0;
        background: #d38202;
        border-radius: 0.8em;
      }
    }
    &-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      & img {
        width: 70%;
        aspect-ratio: 16/9;
        object-fit: contain;
      }
      & p {
        margin: 0.3em 0;
        text-align: center;
      }
    }
  }
  &__nav {
    flex-basis: 23%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.5em;
    gap: 1em;
    & button {
      display: block;
      padding: 0.2em 0.4em;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    &Active {
      color: #fff;
      background: rgba(211, 130, 2, 1) !important;
      border-radius: 0.8em;
    }
  }
}
</style>
