export const test: iContent.Content = {
  section: "Попробуй Туву на вкус",
  links: [
    {
      title: "Национальная кухня",
      name: "home",
    },
  ],
  pages: {
    home: {
      title: "",
      info: [],
      subComponents: [
        {
          type: "kitchen",
          propsData: {
            title: "",
            data: [
              {
                title: "Национальная кухня",
                info: [
                  `Тувинская кухня связана со скотоводством и кочевым образом жизни, поэтому она имеет большое количество мясных блюд и кисломолочных продуктов. А также, имеется сходство с блюдами центральноазиатских и монгольских кухонь.`,
                  `Для приготовления мясных блюд используются наиболее распространенные виды мяса: баранина, говядина, намного реже конина. Мясо яка и северного оленя используется только на ограниченной территории. Традиционным для тувинской кухни является использование пшеницы, ячменя, которые выращивались в ограниченном количестве, и риса. Овощи используются в небольших количествах, они достаточно редко применяются в рецептах.`,
                ],
                image: { title: "Национальная кухня", url: "asset5.webp" },
              },
              {
                title: "«Суттуг шай»",
                info: [
                  `Чай тувинцы употребляют по традиции жителей стран Азии – с молоком, солью и маслом. Принцип приготовления чая на Дальнем востоке и в странах Центральной и Средней Азии примерно одинаков, поэтому тувинский чай (суттуг шай) очень похож на калмыцкий, алтайский, монгольский, тибетский, узбекский и др. Правда некоторые нюансы и ингредиенты могут слегка отличаться. Суттуг шай варят из плиточного зелёного чая, с добавлением овечьего или верблюжьего молока, а после приготовления добавляют соль, топлёный жир (сливочное масло, курдючное сало); ааржы и прожаренная ячменная мука кладутся по желанию в индивидуальные пиалы. К чаю может подаваться популярный десерт из молочных пенок – ореме.`,
                ],
                image: { title: "Суттуг шай", url: "asset7.webp" },
              },
              {
                title: "«Боорзак»",
                info: [
                  `Тувинские пончики в виде небольших шариков, обжаренные в большом количестве масла или животного жира. Тесто для пончиков замешивается крутое из пшеничной муки, каймака (сметаны), горячего молока или подсоленной воды, яйца, сахара и топленого масла. Сейчас иногда в тесто добавляют дрожжи. Тесто расстаивают около 30 минут, нарезают полосочки (примерно 2х5 см.), которые затем делят на шарики.`,
                ],
                image: { title: "Боорзак", url: "asset2.webp" },
              },
              {
                title: "«Бууза»",
                info: [
                  `Это манты, приготовленные на пару. Начинкой также служит баранина (фарш или нарезанная на кусочки). Буузы готовят из баранины или говядины. Мясо измельчают, добавляют сало, лук репчатый и дикий (кулча), перец, соль. Всё хорошо перемешивают. Замешивают крутое тесто из муки с добавлением соли, яйца. Раскатывают на кружочки подходящих размеров. На подготовленные лепешечки теста кладут кусочки фарша, соединяют края, а потом формируют буузы. Варят на пару до готовности.`,
                ],
                image: { title: "Буузы", url: "asset3.webp" },
              },
              {
                title: "«Согажа»",
                info: [`Из предварительно отваренной печени готовится согажа. Подготовленные куски печени оборачивают в сетку (нутряное сало), а затем запекают в духовом шкафу или на углях.`],
                image: { title: "Согажа", url: "asset6.webp" },
              },
              {
                title: "«Тырткан»",
                info: [
                  `Наивкуснейшие, но весьма специфичные, колбасы делают из мяса, требухи, кишок и желудков. Тырткан – из рубленого мяса и кишок, череме (чореме) – отварная колбаса из нарезанного желудка, брюшины и диафрагмы, изиг-хан(«горячая кровь») – кровяная колбаса, хан – кровяная колбаса с добавлением молока. В качестве оболочки колбасам служат кишки.`,
                ],
                image: { title: "Тырткан", url: "asset8.webp" },
              },
              {
                title: "«Кара мун»",
                info: [
                  `Кара мун – традиционный тувинский суп, название которого переводится как «чёрный бульон». Такой характерный тёмный цвет получается от варки баранины и бараньих субпродуктов. Под конец варки добавляют крупы или домашнюю лапшу.`,
                ],
                image: { title: "В 2023 году в Туве особым супом из баранины - кара мун, накормили более двух тысяч человек", url: "asset4.webp" },
              },
              {
                title: "«Ак чем»",
                info: [
                  `В Тыве сохранились традиции производства Ак чем (белая еда, белая пища), объединяющей несколько видов молочной продукции: курут (сушёный молодой сыр), хойтпак (заквашенное молоко, айран), божа (нежные мучнистые остатки хойтпака после изготовления араги; имеет твердые крупинки, сладковатый вкус и серый цвет, часто добавляют ягодные наполнители), ааржы(сухой творог), ореме (десерт, толстые пенки, полученные путем длительного кипячения молока в котле на медленном огне), эжегей (отделившиеся после долгого кипячения сливок на слабом огне сушенные пенки), арага (молочная водка, самогон), чокпек (вареные в топлёном масле пенки, иногда с добавлением семян подорожника и дикоросы), тарак (близок к катыку, простокваше; часто готовят из смеси различного молока), быштак (сыр двух видов – прессованный и высушенный на палке с волокнистой структурой) и т.д. К «белой пище» часто относят и мучные блюда.`,
                ],
                image: { title: "Ак чем", url: "asset1.webp" },
              },
            ],
          },
        },
      ],
    },
  },
};
