export function validateData(year: number, events: { datetime: string; event: string }[]) {
  const errorMessage = [];
  if (!year || isNaN(parseInt(year as unknown as string)) || year < 2023) return { error: true, message: ["Год не валиден!"] };
  if (!Array.isArray(events) || !events.length) return { error: true, message: ["Список событии не валидна!"] };
  for (let i = 0; i < events.length; i++) {
    const date = Date.parse(events[i].datetime);
    if (Number.isNaN(date)) errorMessage.push(`В списоке событии есть не валидный запись даты! ${events[i].datetime}`);
    if (typeof events[i].event !== "string" || events[i].event.length < 1) errorMessage.push(`В списоке событии есть не валидный запись события! ${events[i].datetime} ${events[i].event}`);
  }
  return errorMessage.length > 0 ? { error: true, message: errorMessage } : { error: false, message: [] };
}
