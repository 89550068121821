export const life: iContent.Content = {
  section: "Жизнь Тувы",
  links: [
    {
      name: "home",
      title: "Религия",
    },
    {
      name: "hoomei",
      title: "Хоомей",
    },
    {
      name: "huresh",
      title: "Борьба хуреш",
    },
    {
      name: "racing",
      title: "Конные скачки",
    },
    {
      name: "cutting",
      title: "Камнерезное искусство",
    },
    {
      name: "yaks",
      title: "Высокогорное яководство в Туве",
    },
  ],
  pages: {
    home: {
      info: [
        `В Туве сосуществуют и развиваются языческий шаманизм, буддизм, православие, мусульманство и старообрядчество.`,
        `Народ Тувы дружно празднует и буддийский новый год Шагаа, и Рождество Христово. Популярными становятся древние традиции шаманизма – люди возрождают и проводят обряды поклонения духам предков, тотемным животным, природе.`,
        `Республику посещали духовные лидеры – в сентябре 1992 года здесь побывал Его Святейшество Далай-лама XIV, а в 2011 году Патриарх Московский и всея Руси Кирилл.`,
        `В 2023 году в Кызыле открылся крупнейший буддийский монастырь Тубтен Шедруб Линг, который стал центром притяжения не только для верующих, но и ученых, туристов, любителей архитектуры.`,
      ],
      height: "12em",
      subComponents: [
        {
          type: "photos",
          propsData: {
            title: "",
            data: [
              { title: "Владыка Феофан", url: "asset1.webp" },
              { title: "", url: "asset2.webp" },
              { title: "Его Святейшество Далай-лама XIV в Туве", url: "asset3.webp" },
              { title: "Шаман проводит обряд", url: "asset4.webp" },
            ],
          },
        },
      ],
    },
    hoomei: {
      info: [
        `Тувинское горловое пение в течение не одной сотни лет развивалось народом до высочайшего исполнительского мастерства. Оно является первоосновой всей национальной культуры тувинского народа. Горловое пение – это продукт музыкально-поэтического духа народа. У тувинцев оно предопределено безграничной любовью к природе и животным, так как родилось горловое пение среди кочевников, проводивших большую часть своей жизни под открытым небом. Оттого источником вдохновения у древних горловиков являлась сама природа. Некоторые ученые осмеливаются предположить, что хөөмей – это способ общения со Вселенной.`,
      ],
      title: "Хоомей",
      image: "asset3.jpg",
      imageWidth: "20em",
      height: "auto",
      subComponents: [
        {
          type: "info",
          propsData: {
            title: "",
            data: [
              {
                title: "Стили хоомея",
                action: "Послушать",
                url: "listen",
                data: {
                  title: "Стили Хоомей",
                  height: "auto",
                  info: [
                    `Существует три наиболее известных основных стиля тувинского горлового пения – это хоомей, сыгыт, каргыраа. Подстилей намного больше – хоректээр, думчуктаар, киштедир, сирленнедир, бырланнадыр, уянгылаар, а у каждого артиста своя манера и «изюминка» исполнения.`,
                    `Горловое пение – это подражание звукам природы, поэтому, когда слушаешь хоомей мысленно оказываешься в тувинской степи, горах. Поэтому «каргыраа» называют «медвежьим» стилем, а «эзенгилээр» – это подражание журчанию ручейка и звуку стремян лошади.`,
                    `Послушайте и убедитесь сами:`,
                  ],
                  images: [
                    { title: "Хоомейжи Тувы. Фото Туваонлайн", url: "asset1.webp" },
                    { title: "Ансамбль «Тыва кызы». Автор фото неизвестен", url: "asset2.webp" },
                  ],
                  musicList: [
                    {
                      url: "hoomei.mp3",
                      description: "",
                      name: "Хоомей",
                    },
                    {
                      url: "sygyt.mp3",
                      description: "",
                      name: "Сыгыт",
                    },
                    {
                      url: "kargyraa.mp3",
                      description: "",
                      name: "Каргыраа",
                    },
                    {
                      url: "borbannadyr.mp3",
                      description: "",
                      name: "Борбаннадыр",
                    },
                    {
                      url: "horekteer.mp3",
                      description: "",
                      name: "Хоректээр",
                    },
                    {
                      url: "ezingileer.mp3",
                      description: "",
                      name: "Эзенгилээр",
                    },
                  ],
                },
              },
              {
                title: "Как звучат национальные инструменты",
                action: "Узнать",
                url: "know",
                data: {
                  title: "Как звучат национальные инструменты",
                  info: [],
                  images: [],
                  musicList: [
                    {
                      url: "igil.mp3",
                      description: "Игил. Фото Центра тувинской культуры Тувы",
                      image: "inst/asset4.webp",
                    },
                    {
                      url: "byzaanchy.mp3",
                      description: "Бызаанчы. Фото Центра тувинской культуры Тувы",
                      image: "inst/asset2.webp",
                    },
                    {
                      url: "doshpulduur.mp3",
                      description: "Дошпулуур. Фото Центра тувинской культуры Тувы ",
                      image: "inst/asset3.webp",
                    },
                    {
                      url: "kengirge.mp3",
                      description: "Кенгирге. Фото Центра тувинской культуры Тувы",
                      image: "inst/asset6.webp",
                    },
                    {
                      url: "chadagan.mp3",
                      description: "Чадаган. Фото Центра тувинской культуры Тувы",
                      image: "inst/asset8.webp",
                    },
                    {
                      url: "tun.mp3",
                      description: "Тун из арсенала Духового оркестра Тувы. Автор Сергей Кулик.",
                      image: "inst/asset7.webp",
                    },
                    {
                      url: "bushkuur.mp3",
                      description: "Бушкуур из арсенала Духового оркестра Тувы. Автор Сергей Кулик",
                      image: "inst/asset1.webp",
                    },
                    {
                      url: "kandan.mp3",
                      description: "Кандан из арсенала Духового оркестра Тувы. Автор Сергей Кулик",
                      image: "inst/asset5.webp",
                    },
                  ],
                },
              },
              {
                title: "Плейлист тувинских песен",
                action: "Добавить к себе",
                url: "add",
                data: {
                  title: "Плейлист тувинских песен",
                  info: [
                    `Тувинские группы и исполнители известны во всем мире. У горлового пения настолько преданные поклонники, что многие учат тувинский язык и приезжают в Туву, а некоторые и вовсе переезжают жить в труднодоступную сибирскую республику.`,
                    `Рекомендуем также послушать группы «Хуун-Хуур-Ту», «Ят-Ха», «Алаш», «Чиргилчин», «Khoomei beat», «Одучу», ансамбли «Тыва», «Тыва кызы», а также произведения Национального оркестра и Духового оркестра Правительства Тувы им. Тимура Дулуша.`,
                    `Собрали для вас плейлист, наведите камеру на QR-код и откройте для себя тувинскую музыку`,
                  ],
                  images: [{ title: "", url: "asset3.webp", width: "20em" }],
                  musicList: [],
                },
              },
            ],
          },
        },
      ],
    },
    huresh: {
      info: [
        `Древнейший спорт популярен в Туве также, как и футбол в Бразилии. Борцов хуреша уважают, болеют за них и следят за успехами. Любовь к этому спорту прививают с самого детства.`,
        `Борьба хуреш – это не просто схватка двух атлетов, это, прежде всего, поединок двух духовных сил, ведь издревле считается, что в хуреше присутствуют три составляющих победы: боевой дух, мастерство и масса борца. `,
        `Выходящего на поединок борца сопровождает его секундант (моге салыкчызы), одетый в национальную одежду, который зазывает другого атлета на борьбу и знакомит зрителей и соперника с титулами своего борца. В обязанность секунданта входит поднятие боевого духа и защита интересов борца. `,
        `Перед началом состязаний все борцы одновременно исполняют «Девиг» – «танец орла» – приветствуя собравшихся. Исполняется танец в быстром темпе, демонстрируя ловкость, силу борца и придаёт каждому участнику уверенность в победе.`,
        `В хуреше нет разделения по весу спортсменов, их возрасту, поэтому считается, что состояние тела и духа тесно взаимосвязаны между собой. Внутренняя неуравновешенность, беспокойство, растерянность отражаются и на действиях бойца. Появляются суетливость, беспорядочные движения, волнение и растерянность, наступает упадок сил, нарушается ритм и гармония жизни.`,
      ],
      height: "16em",
      subComponents: [
        {
          type: "photos",
          propsData: {
            title: "",
            data: [
              { title: "", url: "asset1.webp" },
              { title: "", url: "asset2.webp" },
              { title: "", url: "asset3.webp" },
              { title: "Схватка борцов хуреша", url: "asset4.webp" },
            ],
          },
        },
      ],
    },
    racing: {
      info: [
        `Лошадь занимает особое место в жизни тувинца. Это не просто домашнее животное, это настоящий друг. Уметь держаться в седле должен каждый тувинец с самого детства.`,
        `Именно поэтому в Туве конные скачки занимают второе место по популярности и масштабности среди различных видов спорта после хуреша.`,
      ],
      height: "12em",
      subComponents: [
        {
          type: "photos",
          propsData: {
            title: "",
            data: [
              { title: "Конные скачки", url: "asset1.webp" },
              { title: "Мальчик на лошади", url: "asset2.webp" },
              { title: "", url: "asset3.webp" },
              { title: "", url: "asset4.webp" },
              { title: "", url: "asset5.webp" },
              { title: "", url: "asset6.webp" },
              { title: "", url: "asset7.webp" },
              { title: "", url: "asset8.webp" },
              { title: "", url: "asset9.webp" },
            ],
          },
        },
      ],
    },
    cutting: {
      info: [
        `Когда-то мастера Тувы хранили в секрете знания об обработке красивого и пластичного камня агальматолита, называя его «чонар-даш», в переводе «камень, который можно резать». Сегодня в Туве сложились школы мастеров, династии, художественный камнерезный промысел которых является одним из достояний народного искусства России.`,
        `Не изменяя многолетней традиции, тувинские мастера черпают идеи из того, что их окружает. Это изображения домашних или диких животных, или навеянные эпосами мифические персонажи. Покрывая фигурки резным орнаментом, камнерезы с ювелирной точностью передают характер животного или человека.`,
        `В России агальматолит добывают на Урале, Алтае, в Бурятии и Тыве, обычно кустарным способом по традиции в конце сентября – начале октября, когда земля промёрзнет.`,
        `Перед началом добывания агальматолита совершается ритуал: возле горы кропят молоком на четыре стороны света с целью задобрить духов – хозяев этого места.`,
      ],
      height: "16em",
      subComponents: [
        {
          type: "photos",
          propsData: {
            title: "",
            data: [
              { title: "Дойбухаа Д.Х. Сарлык", url: "asset1.webp" },
              { title: "Донгак Х.Б. Улулулар (два дракона)", url: "asset2.webp" },
              { title: "Монгуш М.О. Табунщик", url: "asset3.webp" },
              { title: "Ооржак Ю.Д. Пастух", url: "asset4.webp" },
              { title: "Тойбузаа Х.К. Аратка", url: "asset5.webp" },
            ],
          },
        },
      ],
    },
    yaks: {
      info: [],
      subComponents: [
        {
          type: "animals",
          propsData: {
            title: "",
            data: [
              {
                title: `Высокогорное яководство в Туве`,
                info: [
                  `В Туве в большом количестве яки сохранились только в Монгун-Тайгинском районе.`,
                  `Обширные территории, находящиеся на большой высоте над уровнем моря имеют такое сложное сочетание природно-климатических и кормовых условий, что содержать на них крупный рогатый скот, овец и лошадей трудно. Эти земли хорошо осваивают домашние яки.`,
                  `Символ Монгун-Тайги – сарлык (як). Он на гербе района.`,
                ],
                image: { title: "Герб Монгун-Тайгинского района", url: "asset1.webp" },
              },
              {
                title: `Продукты, получаемые от яка`,
                info: [
                  `Мясо яка лечебное, так как яки живут в горной местности и питаются лечебными травами. Самка яка дает 1-1,5 литра молока в сутки. Сладковатое молоко необычайно жирное – до 10% и богато витаминами. Если наверху полного бачка поставить «шай согаажы» (чайную ступку), она сможет держаться долгое время – такова жирность молока.`,
                  `Из молока готовят «хойтпак» – особым образом заквашенное молоко, сметану, сыр, «ааржы» – гуща процеженного молока, оставшегося после перегонки молочной водки, «курут» – вид сушеного творога, молочную водку – «арагу».`,
                ],
                image: { title: "Яки Монгун-Тайгинского района,", url: "asset2.webp" },
              },
              {
                title: `Продукция, получаемая из шерсти и шкуры яка`,
                info: [
                  `Из шерсти яка можно вязать шнуры, арканы и одежду, «мончар» – ошейник для телят, подпругу, «ог куру» – ленту для опоясывания юрты, «чеп» – веревку, сплетенную из длинных остистых волос. Из кожи изготовляют сумки для зерна, одежды, они очень удобны и практичны при перекочевках на новую стоянку.`,
                ],
                image: { title: "Яки Монгун-Тайгинского района...", url: "asset3.webp" },
              },
              {
                title: `Особенности характера яка`,
                info: [
                  `Яки – животные свободолюбивые. Никаких специальных «кажаа» («загонов») для них не строят. Они живут долго – до тридцати лет.`,
                  `Отел у яков происходит в феврале. Обычно в стаде оставляют несколько быков, остальных до достижения одного года кастрируют. Чтобы улучшить потомство, обмениваются быками из разных бригад. Если не делать такого обмена, телята яка родятся маленькими, будут хворать. Хотя опытные чабаны всегда отличат своих яков от чужих, родовые метки, на всякий случай, всем телятам делают.`,
                  `Сутки напролет они пасутся на горных пастбищах, а зимой добывают себе пропитание из-под снега. Густая шерстяная «юбка» служит им подстилкой. Зимой, когда температура в тувинских горах опускается до минус 40, животные ложатся отдыхать прямо на снег.`,
                  `Зимой и летом яки всегда в тайге, не боятся даже волков. Зимой яки ложатся на снег обязательно навстречу ветру и чуют запах приближающихся хищников. Лягут в одном месте, и волчьи клыки не достигают кожи благодаря густой шерсти. Взрослые яки могут себя защитить: мощный бык забодает волка своими рогами.`,
                  `И летом, и зимой пасутся, питаясь подножным кормом. Они постоянно находятся в движении и зимой за день могут пройти десятки километров. Летом сами к стоянке придут, а ближе к зиме надо обязательно класть неподалеку от палатки каменную соль, тогда обязательно вернутся лизать ее.`,
                ],
                image: { title: "Яки Монгун-Тайгинского района.", url: "asset4.webp" },
              },
              {
                title: `Як как транспортное средство`,
                info: [
                  `Используются яки и как рабочий скот. Веками он отлично служил кочевым жителям гор в транспортных целях. В Монгун-Тайге и сейчас есть места, куда можно добраться только на конях и на яках. С наступлением холодов на двух яках, приученных к кочевке, перебираются к зимовке. Як способен нести тяжелые вьюки по самым труднопроходимым горным тропам или даже совсем без дорог, по снегу. Он с легкостью, не считаясь с крутизной, прыгает с уступа на уступ, несмотря на свои внушительные размеры.`,
                ],
                image: { title: "Яки Монгун-Тайгинского района.", url: "asset5.webp" },
              },
            ],
          },
        },
      ],
    },
  },
};
