import { allContents } from "@/share/libs";
import { Module } from "vuex";

export const content: Module<{ section: iContent.Content }, iStore.Store> = {
  namespaced: true,
  state: {
    section: {
      links: [],
      pages: {},
      section: "",
    },
  },
  mutations: {
    SET_SECTION(state, section) {
      state.section = section;
    },
  },
  actions: {
    GET_SECTION({ commit }, section) {
      return new Promise((resolve, reject) => {
        try {
          if (section in allContents) {
            commit("SET_SECTION", allContents[section]);
            resolve(true);
          } else {
            resolve({ error: true });
          }
        } catch (error) {
          reject();
        }
      });
    },
  },
};
