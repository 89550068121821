export const holidays: iContent.Content = {
  section: "Президентские каникулы в Туве",
  links: [
    {
      title: "Президентские каникулы",
      name: "home",
    },
    {
      title: "Интересные моменты и встречи",
      name: "moments",
    },
    {
      title: "Путин о Туве и ее народе",
      name: "about",
    },
    {
      title: "Жители Тувы о Владимире Путине",
      name: "people",
    },
  ],
  pages: {
    home: {
      info: [
        `Ни для кого не секрет, что Владимир Путин предпочитает отдых в России. Однако к Сибири он действительно прикипел душой. Множество раз свой краткосрочный отпуск президент проводил на просторах не просто сибирской, а именно тувинской тайги. Подводная рыбалка, сбор грибов и ягод, хождение по горам, разведение костра — это далеко не полный список дел, которыми занимался Владимир Путин на отдыхе в Туве.`,
        `Впервые с официальным визитом Владимир Путин прилетел в Туву летом 2007 года вместе с князем Монако Альбером II. Тогда он посетил раскопки древней крепости Пор-Бажын, сплавился по рекам Енисей и Хемчик. Впечатления от первого трехдневного пребывания в Туве были настолько сильными, что уже после отъезда на пресс-конференциях в Бишкеке и Челябинской области Путин признался журналистам: «Я много где был, много что видел, но такой мощной природы не видел никогда».`,
        `Именно с тех пор визиты главы российского государства в Туву стали регулярными, а неофициальные источники ежегодно сообщают о традиционном отдыхе Владимира Путина в излюбленных природных красотах республики каждое лето или осенью.`,
      ],
      image: "asset14.webp",
      imageWidth: "40em",
      hidden: "unset",
      subComponents: [],
    },
    moments: {
      info: [],
      subComponents: [
        {
          type: "byPhoto",
          propsData: {
            title: "",
            data: [
              {
                title: "Август 2007 год",
                info: [
                  `В августе 2007 года, во время первого визита главы государства в Туву в компании князя Монако Альбера II, один из вертолетов, на котором они передвигались по республике, на время превратился в медицинский. В лагерь археологов ранее в крепости Пор-Бажын за медицинской помощью обратилась семья из деревни Кунгурут, расположенной в восьми километрах от места раскопок. Несколько дней назад у них заболела 10-тимесячная дочь, и с каждым днем ее состояние только ухудшалось. Врачи «Центроспаса» МЧС, побывавшие в деревне для осмотра ребенка, приняли решение для незамедлительной эвакуации, в которой и был задействован один из вертолетов президента России. На нем девочку с родителями доставили в столицу Тувы Кызыл.`,
                ],
                image: { title: "Владимир Путин и князь Монако Альбер II в Туве_ Фото Дмитрия Астахова", url: "asset4.webp" },
              },
              {
                title: "Август 2009 год",
                info: [
                  `В августе 2009 года Владимир Путин отдыхал в палаточном лагере на берегу реки Хемчик. На одной из стоянок в безлюдном горном местечке он заметил отару овец, которую вел местный пастух. Для чабана Дукар-оола Маскыр-оола встреча оказалась большим сюрпризом. Путин пригласил скотовода попить с ним чаю. В ответ тот предложил ему посмотреть его жилище. Когда Путин согласился, пастух отправился домой и через некоторое время вернулся вместе с сыном и лошадью для него. Дукар-оол Маскыр-оол познакомил Путина со своими двумя дочерями. На память о встрече он подарил сыну пастуха часы, которые снял со своей руки, а гостеприимному хозяину — свой охотничий нож.`,
                ],
                image: { title: "В августе 2009 года во время отдыха в Туве Владимир Путин посетил юрту местного чабана", url: "asset2.webp" },
              },
              {
                title: "Июль 2013 год",
                info: [
                  `В июле 2013 года во время рыбалки на озере Токпак-Холь в Тоджинском районе Тувы Владимир Путин поймал 21-килограммовую щуку. Чтобы достать ее из воды понадобилось несколько минут. Размер рыбы удивил даже местного егеря. «Первая моя такая щука, должен признаться по-честному, долго мы за ней гонялись, и взяла она хорошо, но не заглотила, я боялся ее дернуть слишком, чтобы не оборвала губу себе», - поделился тогда впечатлениями Владимир Путин.`,
                ],
                image: { title: "Владимир Путин во время рыбалки в Тоджинском районе Тувы", url: "asset3.webp" },
              },
              {
                title: "Сентябрь 2014 год",
                info: [
                  `В сентябре 2014 года во время праздничных мероприятий по случаю 100-летия единения России и Тувы Владимир Путин присутствовал на традиционных соревнованиях по хурешу (вольной борьбе), в котором приняли участие 310 борцов со всей Тувы. Почитатель вольной борьбы и дзюдо Владимир Путин приветствовал стоя тувинских богатырей.`,
                ],
                image: { title: "Выступление на праздновании 100-летия вхождения Тывы в состав России_Фото Kremlin.ru", url: "asset9.webp" },
              },
              {
                title: "",
                info: [
                  `Он с интересом наблюдал за техникой борьбы известного хурешиста и в то время единственного обладателя звания «Исполин Республики Тыва» Аяса Монгуша. Он не участвовал в соревнованиях пять лет, но зрители его не забыли, с возгласами встретили его появление. Для того чтобы увидеть ритуал в исполнении Аяса Монгуша, президент даже выглянул из своего ложа. К сожалению, Аяс Монгуш не стал победителем этих соревнований, с Исполином осталась всенародная любовь и слава 17-кратного победителя национальной борьбы хуреш.`,
                ],
                image: { title: "На театрализованном спортивном празднике, посвящённом 100-летию вхождения Тывы в состав России", url: "asset10.webp" },
              },
            ],
          },
        },
      ],
    },
    about: {
      info: [],
      subComponents: [
        {
          type: "quote",
          propsData: {
            title: "",
            data: [
              {
                title: ``,
                info: [
                  `«Тувинский народ смог сохранить свою самобытность, свою культуру, свой язык, веру своих предков. А тувинцам есть чем гордится, это народ с многовековой культурой, неслучайно здесь и Долина царей скифов, здесь Пор-Бажын, здесь очень много того, чем может гордиться любая республика и любой народ не только в России, а во всем мире. Здесь удивительный, добрый сердцем, талантливый народ.»`,
                ],
                image: { title: "В. В. Путин", url: "asset1.webp" },
              },
              {
                title: ``,
                info: [
                  `«Тыва — это удивительный край, удивительная, замечательная, уникальная природа. Здесь и Саянские горы, и Енисей, множество озер, рек, которыми можно гордиться и восхищаться бесконечно. Но здесь и огромные природные богатства, которые всем нам еще предстоит освоить, создавая новые производства и новые рабочие места. Но, как говорят, у вас, в Тыве, если соединяются ручейки - река, а если объединяются люди — это сила. И, я уверен, что все задачи, которые стоят перед нами, мы, безусловно, решим, мы победим!» (Путин В. В.)`,
                ],
                image: { title: "В. В. Путин", url: "asset2.webp" },
              },
            ],
          },
        },
      ],
    },
    people: {
      info: [],
      subComponents: [
        {
          type: "quote",
          propsData: {
            title: "",
            data: [
              {
                title: `Яна Кочегина:`,
                info: [`Очень уважаю Путина как политика и считаю, что у России самый крутой глава государства – с харизмой настоящего лидера, умного и уверенного в себе.`],
                image: { title: "Яна Кочегина", url: "quote.png" },
              },
              {
                title: `Ольга Дамдын:`,
                info: [
                  `Посмотрите вокруг, строятся новые школы, разве в истории Тувы было такое? Чтобы за раз строили 10 школ, ФАПов, а сейчас это делается, претворяется вжизнь. Это все зависит от нашего руководства, от нашего президента, который перед собой поставил очень трудную задачу.`,
                ],
                image: { title: "Ольга Дамдын", url: "asset12.webp" },
              },
              {
                title: `Татьяна Рамазанова:`,
                info: [
                  `Благодаря принципиальной позиции лидера нашей страны Владимира Владимировича Путина на развитие, мы видим, как меняется наша страна, наша Россия. Мы видим, как меняется наша республика, город Кызыл! Вы посмотрите, сколько появляется новых локаций и не только в центре. Я помню город Кызыл, я родилась здесь, я журналист с 30-летним стажем работы. Узкие улочки, ветхие дома, сейчас этого становится все меньше и меньше. И мы видим совсем другую картину, картину процветающей Тувы!`,
                ],
                image: { title: "Татьяна Рамазанова", url: "asset13.webp" },
              },
            ],
          },
        },
      ],
    },
  },
};
