export const discover: iContent.Content = {
  section: "Открыть Тыву",
  links: [
    {
      title: "Знаковые места",
      name: "home",
    },
    {
      title: "Озера",
      name: "lakes",
    },
    {
      title: "Места силы",
      name: "pilgrimage",
    },
    {
      title: "Горы",
      name: "mountains",
    },
    {
      title: "Календарь",
      name: "calendar",
    },
    {
      title: "А еще?",
      name: "else",
    },
  ],
  pages: {
    home: {
      info: [],
      subComponents: [
        {
          type: "byPhoto",
          propsData: {
            data: [
              {
                info: [
                  `Скульптурный комплекс «Центр Азии» – главная достопримечательность Тувы, которая символизирует географический центр азиатского материка`,
                  `В 2014 году комплекс был обновлен к 100-летию единения Тувы и России по проекту всемирно известного художника Даши Намдакова.`,
                  `Обелиск представляет собой шар, олицетворяющий планету Земля, который держат три арзылана – мифические животные, схожие со львами.`,
                  `Вокруг обелиска установлены 12 скульптур, изображающих животных буддийского гороскопа. Шпиль обелиска венчает «солнечный олень».`,
                  `Рядом с обелиском – скульптурная композиция «Царская охота».`,
                  `Местоположение: ул. Красных партизан, 9 в г. Кызыле`,
                ],
                image: { title: "Автор: Пресс-служба Правительства РТ", url: "asset1.webp" },
              },
              {
                info: [
                  `Буддийский монастырь «Тубтен Шедруб Линг» – самый крупный монастырь в России. Его история началась более 30 лет назад – с 21 сентября 1992 года, когда Его Святейшество Далай-лама XIV в первые побывал с визитом в Туве. Именно тогда он освятил и благословил место возведения монастыря. В 2020 году Его Святейшество дал ему название «Тубтен Шедруб Линг», что переводится как «Обитель объяснения и практики учения Будды Шакьямуни».`,
                  `Одной из главных реликвий монастыря является «Шарира тела Сугаты» — мощи Будды Шакьямуни. По наставлению Далай-ламы, реликвию поместили в сердечной части статуи Будды Шакьямуни.`,
                  `Местоположение: ул. Московская, 72 в г. Кызыле`,
                ],
                image: { title: "Автор: Буян Ооржак", url: "asset12.webp" },
              },
              {
                info: [
                  `Буддийский храм «Цеченлинг», который переводится как «Обитель безграничного сострадания», был заложен в 1998 году.`,
                  `Это действующий храм, на территории которого обитают служители — ламы. Любой из посетителей может с ними побеседовать и даже принять участие в занятиях и молебнах.`,
                  `Прежде чем зайти в храм, нужно обойти его три раза. Когда идешь, обязательно должен коснуться всех углов. Из-за этой традиции все углы храма прилично затерты.`,
                  `Местоположение: ул. Щетинкина-Кравченко, 1 в г. Кызыле`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset23.webp" },
              },
              {
                info: [
                  `Буддийский молитвенный барабан – один из ярких памятников Кызыла. Он был возведен на центральной площади столицы республики в 2006 году.`,
                  `Его построили индийские монахи. Внутри барабана находятся тысячи свернутых свитков с мантрами (молитвами). Один оборот барабана по часовой стрелке равнозначен прочтению всех мантр одновременно. В народе говорят, что вращение барабана приносит удачу, благополучие, здоровье и очищает душу. Вращать барабан можно не только буддистам, а всем желающим.`,
                  `Местоположение: в самом центре Кызыла на центральной площади — между улицами Ленина и Кочетова`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset31.webp" },
              },
              {
                info: [
                  `Свято-Троицкий храм г. Кызыла. Православие в Туве появилось совсем недавно – в 19 веке, вместе с искателями золота. Сегодня христианство исповедует большая часть русского населения. Свято-Троицкий храм, который является одной из главных достопримечательностей епархии, был построен в 2001 году. Архитектура храма вписывается в стиль русского классицизма. При нем также открыта школа, работает библиотека. Во время каникул здесь работает лагерь.`,
                  `Местоположение: на ул. Оюна Курседи, 112 в г. Кызыле`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset32.webp" },
              },
              {
                info: [
                  `Музыкально-драматический театр имени В. Кок-оола`,
                  `Единственный профессиональный драматический театр в Туве, являющийся ведущим центром театрального искусства региона.`,
                  `В настоящее время репертуар театра формируют порядка 30 действующих спектаклей по тувинской, русской и зарубежной драматургии, которые нацелены на зрительскую аудиторию всех возрастов. Его жанровый ряд весьма разнообразен: комедии, социальные и философские драмы, социально-нравственные и семейно-бытовые драмы, трагедии, мюзиклы, легенды, сказания, детские музыкальные сказки, исторические драмы.`,
                  `Местоположение: ул. Ленина, 33 в г. Кызыле`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset33.webp" },
              },
              {
                info: [
                  `Национальный музей Республики Тыва – один из главных культурно-образовательных центров республики. Музей располагает уникальными экспонатами, такими как оригинал первого тувинского букваря, осколок метеорита, найденный на реке Ченге; коллекция древних шаманских атрибутов, буддийская коллекция, сутры, большая коллекция культовых предметов из Верхнечаданского хурээ, золотые пластины, бронзовая бляха, украшения, погребальная колода из кургана Аржаан, коллекция тувинских камнерезов и многое другое.`,
                  `Местоположение: ул. Титова, 30, в г. Кызыле`,
                ],
                image: { title: "Автор: Пресс-служба Правительства РТ", url: "asset34.webp" },
              },
              {
                info: [
                  `Кижи-кожээ – каменное изваяние воина, которому насчитывается более 1000 лет. Наиболее известный кожээ – «Чингисхан». Изготовлен из красного гранита и продуман до мелочей. Считается, что если прошептать на ухо воину свое желание, то оно сбудется.`,
                  `Всего в республике насчитывается около 20 таких изваяний. Они представляют собой каменные изваяния с различными сосудами в руках.`,
                  `Местоположение: в Барун-Хемчикском районе Республики Тыва, Сумон-Бижиктиг-Хая.`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset35.webp" },
              },
              {
                info: [
                  `Древняя крепость Пор-Бажын, датируемая 777 годом. Крепость была возведена по китайским технологиям и со всех сторон омывается озером Тере-Холь. По своему виду сооружение напоминает квадрат, который огорожен массивными стенами по всему периметру. Чем-то напоминает французский Форт-Баярд.`,
                  `Местоположение: в Тере-Хольском районе, в 250 км от г. Кызыла`,
                ],
                image: { title: "Автор: Российское географическое общество", url: "asset36.webp" },
              },
              {
                info: [
                  `Долина царей - захоронения вождей и их семей скифского периода.`,
                  `Здесь до сих пор ведутся археологические раскопки. Все золотые украшения, доспехи, утварь и многие другие предметы жизни и обитания скифов были сохранены и переданы в Национальный музей Республики Тыва.`,
                  `Наиболее изученными и известными являются 2 кургана – «Аржаан-1» и «Аржаан-2». В этих двух захоронениях было обнаружено более пяти тысяч золотых изделий, которые ученые относят к 7-10 веку до н.э.`,
                  `Местоположение: в Пий-Хемском районе Республики Тыва, близ г. Турана`,
                ],
                image: { title: "Автор: Российское географическое общество", url: "asset2.webp" },
              },
              {
                info: [
                  `Устуу-Хурээ - буддистский монастырь, построенный в 1905 году.`,
                  `Это один из самых крупных и известных буддийских монастырей республики. В свое время здесь был центр духовности и государственности Тувы. На территории хурээ была заложена основа тувинского алфавита, отчеканена первая монета Тувинской Народной Республики.`,
                  `Устуу-Хурээ в 30-е годы прошлого века был разрушен, а в 2012 году состоялось торжественное открытие вновь построенного хурээ. Устуу-Хурээ стал храмом тысячи Будд.`,
                  `Местоположение: в Дзун-Хемчикском районе Республики Тыва, близ г. Чадан`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset3.webp" },
              },
              {
                info: [
                  `Убсунурская котловина – является объектом всемирного наследия ЮНЕСКО.`,
                  `Заповедник существует с 1993 года и занимает площадь более 925 тысяч гектаров. Расположение в чаше естественного происхождения способствовало созданию уникальной экосистемы. Примечательные природные объекты: пресноводное озеро Торе-Холь, гора Ямаалыг – место захоронений у древних народностей.`,
                  `Местоположение: на границе Республики Тывы и Монголии`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset4.webp" },
              },
              {
                info: [
                  `Алдын-Булак – этнокультурный комплекс`,
                  `Построен как своеобразный макет вселенной, где роли звёзд и светил отведены юртам разных размеров и назначений. Хотя юрты и чумы оформлены максимально аутентично, в Алдын-Булаке есть все удобства, включая места для проживания, ресторан, баню, автостоянку.`,
                  `Местоположение: в 45 км от г. Кызыла`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset5.webp" },
              },
            ],
          },
        },
      ],
    },
    lakes: {
      info: [],
      subComponents: [
        {
          type: "byPhoto",
          propsData: {
            data: [
              {
                info: [
                  `Ожерелье озер Тувы является одной из самых значимых природных достопримечательностей. Их на ее территории насчитывается порядка 6700! Они являются не только прекрасными природными достопримечательностями, но и важными экологическими объектами. Озера республики прекрасно сочетаются с красивыми пейзажами и горами, отличаются своими чистыми и прозрачными водами. Отдельные из них являются священными местами, где проводятся различные обряды и праздники. Для туристов озера Тувы предлагают множество возможностей для активного отдыха и восстановления сил.`,
                ],
                image: { title: "", url: "asset4.webp" },
              },
              {
                info: [
                  `Дусь-Холь – тувинское «мертвое» море. Концентрация соли здесь позволяет спокойно лежать на воде. Питают Дус-Холь три родника, атмосферные осадки и роднички, выходящие на дне озера. Лечебными факторами являются вода (рапа) озера и грязь.`,
                  `Сюда приезжают в первую очередь лечить суставы, кожные заболевания и нервную систему.`,
                  `Местоположение: в 42 км от г. Кызыла`,
                ],
                image: { title: "Автор: Информационный центр туризма Республики Тыва", url: "asset6.webp" },
              },

              {
                info: [
                  `Торе-Холь – пресноводное озеро, один берег которого относится к российской территории, а другой к Монголии.`,
                  `Местность вокруг пустынная, но для любителей экотуризма есть все необходимое. Считается, что здесь лучшие песчаные пляжи.`,
                  `Местоположение: в 250 км от г. Кызыла`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset30.webp" },
              },

              {
                info: [
                  `Азас – жемчужина Тувы. Или по-другому Тоджа стала «меккой» туристов. Своей первозданной красотой она, как магнит, притягивает их со всей России.`,
                  `Символом озера является белая кувшинка, каждое лето здесь распускаются цветы лотоса.`,
                  `В озере водится много щуки, сига, сороги, налима, окуня, хариуса, ленка, язя, пеляди.`,
                  `Местоположение: в Тоджинском районе Республики Тыва`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset7.webp" },
              },

              {
                info: [
                  `Чагытай – самое глубокое пресное озеро Тувинской котловины (в некоторых местах – до 17 метров в глубину). Берега у озера каменистые, но кое-где имеются и песчаные – так называемые «Золотые пески». Чагытай славится рыбным изобилием, но рыбалка, как вид отдыха, здесь не разрешена.`,
                  `Местоположение: в Тандинском районе Республики Тыва`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset8.webp" },
              },

              {
                info: [
                  `Кара-Холь – одно из красивейших озер Тувы, памятник природы регионального значения. От отражения горных хребтов вода озера кажется черной, отсюда его название Кара-Холь, в переводе с тувинского –  «Черное озеро».`,
                  `Озеро богато хариусом, а на берегах встречаются редкие виды животных, такие как алтайский горный баран, снежный барс, дикий олень.`,
                  `Местоположение: в Бай-Тайгинском районе Республики Тыва`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset9.webp" },
              },
            ],
          },
        },
      ],
    },
    pilgrimage: {
      info: [],
      subComponents: [
        {
          type: "byPhoto",
          propsData: {
            data: [
              {
                info: [
                  `Самая большая статуя Будды в России. Здесь Будда Шакьямуни изображен в момент просветления. У подножия монумента – цветок лотоса – символ чистоты и самоотречения.`,
                  `Место расположения религиозной скульптуры представляет из себя смотровую площадку, с которой открывается прекрасный вид на слияние двух рек – Большого и Малого Енисея, а также на набережную Кызыла, где расположен архитектурный комплекс «Центр Азии».`,
                  `Местоположение: в Кызылском районе Республики Тыва`,
                ],
                image: { title: "Автор: Пресс-служба Правительства РТ", url: "asset10.webp" },
              },

              {
                info: [
                  `Статуя Будды, установленная на вершине горы Догээ`,
                  `Высота монумента составляет более девяти метров, а внутри него помещены 333 тома священных книг, а также земля со святых мест, драгоценные металлы и камни. Поднимаясь на вершину 1000 метров, по дороге можно увидеть выложенную из камней одну из самых больших мантр в мире «Ом мани падме хум».`,
                  `Считается, что подъем на вершину горы поднимает дух и избавляет от дурных мыслей.`,
                  `Местоположение: на правом берегу реки Енисей г. Кызыла`,
                ],
                image: { title: "Автор: Пресс-служба Правительства РТ", url: "asset11.webp" },
              },

              {
                info: [
                  `Буддийская ниша. Этот памятник буддийского искусства XIII века. Выбитая в скале на высоте четырех метров ниша – священное место Тувы. На задней стене ниши – барельеф Будды, по бокам – ученики Амитабхи. Двое стражников охраняют вход в пещеры. По преданиям, если Дух человека готов к познанию, то стражники его пропустят, и он окажется в другом измерении. Уникальность памятника еще и в том, что увидеть его можно только раз в год, когда идет сброс воды в Саяно-Шушенское водохранилище.`,
                  `Местоположение: В Чаа-Хольском районе Республики Тыва`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset13.webp" },
              },
            ],
          },
        },
      ],
    },
    mountains: {
      info: [],
      subComponents: [
        {
          type: "byPhoto",
          propsData: {
            data: [
              {
                info: [
                  `Могун-Тайга, если перевести означает – «Серебряная гора».`,
                  `Здесь самая высокая точка Республики Тыва. Альпинисты со всех уголков России стремятся покорить эту гору. Поэтому для туристов проложены специальные маршруты разной степени тяжести. Здесь раскинулись удивительной красоты альпийские луга и каменистые тундры. А у подножья до сих пор живут местные жители, которые занимаются яководством.`,
                  `Местоположение: в Монгун-Тайгинском районе Республики Тыва`,
                ],
                image: { title: "Автор: Пресс-служба Правительства РТ", url: "asset14.webp" },
              },

              {
                info: [
                  `Хайыракан - относится к числу священных тувинских объектов, как для шаманов, так и для буддистов. Абсолютная высота – более тысячи метров. Освящена Его Святейшеством Далай-ламой XIV в 1992 году. Есть несколько версий происхождения названия, в том числе из-за внешней схожести с медведем, пьющим воду.`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset15.webp" },
              },

              {
                info: [
                  `Кежеге переводится как «мужская косичка». Если посмотреть на гору сверху, то можно увидеть, что по своей форме она напоминает кольцо, в центре которого течет река. Внутри горы есть алтари и лабиринты. Многие верят, что если загадать здесь желание, написать его на бумаге и положить между скал, то оно обязательно сбудется.`,
                  `Местоположение: в Эрзинском районе Республики Тыва`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset16.webp" },
              },

              {
                info: [
                  `Бай-Тайга считается уникальным местом на планете. На ее вершине часто бывают крутящиеся в виде воронки снежные бури. Некоторые считают, что это ни что иное как черная дыра, которая выходит за пределы Земли. В любое время года Бай-Тайга скрывается в тумане и облаках.`,
                  `Под отвесными скалами у подножия самой высокой вершины находится озеро Ногаан Холь. Оно считается таинственным местом силы с особой энергетикой. Вода в озере течёт по горе не сверху вниз, а наоборот снизу вверх.`,
                  `Здесь ежегодно совершается обряд освящения Бай-Тайги.`,
                  `Местоположение: в Бай-Тайгинском районе Республики Тыва`,
                ],
                image: { title: "Автор: Пресс-служба Правительства РТ", url: "asset17.webp" },
              },

              {
                info: [
                  `Буура – по-тувински верблюд, самое крупное животное в республике. Своими очертаниями гора похожа на лежачего верблюда.`,
                  `Каждый год в середине июня здесь проходит обряд освящения горы. Обряд собирает огромное количество верующих. Освящение горы поднимает силы морального состояния (сулде) и духа (хей-аът). У тех, кто участвовал в обряде, год проходит удачно и благополучно.`,
                  `Местоположение: в Улуг-Хемском районе Республики Тыва`,
                ],
                image: { title: "Автор: Пресс-служба Правительства РТ", url: "asset18.webp" },
              },

              {
                info: [
                  `Красные пещеры привлекают туристов своим уникальным скальным пейзажем и красным оттенком камней, который достигается за счет содержащихся в них оксидов железа. В местах пещер можно насладиться красотой экзотических растений и удивительной природой.`,
                  `В древности в этих пещерах шаманы совершали экстатические путешествия и проводили ритуалы и обряды.`,
                  `Местоположение: в 40 км от г. Кызыла`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset19.webp" },
              },

              {
                info: [
                  `Танды-Уула – один из самых сложных и красивых туристических маршрутов Тувы. Высота хребта составляет 2560 метров над уровнем моря.`,
                  `В маршруте можно увидеть цепь озер: Чылыг-Холь, Ногаан-Холб и Кара-Холь.`,
                  `Местоположение: Чеди-Хольский район Республики Тыва`,
                ],
                image: { title: "Автор: Пресс-служба Правительства РТ", url: "asset20.webp" },
              },

              {
                info: [
                  `[Целебные источники, где можно оздоровиться`,
                  `Аржаан Чойган» – здесь горячие и холодные углекислые воды.`,
                  `Температура в горячих источниках превышают +40 °С. Сюда приезжают для лечения заболеваний нервной системы, желудочно-кишечного тракта, сердечно-сосудистой системы и многих других.`,
                  `Местоположение: на границе Республики Тыва и Бурятии`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset21.webp" },
              },

              {
                info: [
                  `Аржаан Уш-Белдир. Он расположен на высоте 1300 метров над уровнем моря, на самой границе с Монголией, на слиянии трек рек, откуда и получил свое название. Температура воды в скважинах и родниках – от 55 до 85 градусов. Исследования показали также, что минеральные запасы подземных вод источника фактически безграничны.`,
                  `Этот аржаан излечивает заболевания нервной системы, опорно-двигательного аппарата, кожные, гинекологические, андрологические и сердечно-сосудистые болезни.`,
                  `Местоположение: в 360 км к востоку от г. Кызыла`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset22.webp" },
              },

              {
                info: [
                  `Аржаан Шивилиг – один из самых популярных радоновых источников республики. Аржаан находится на вершине горы и даже в самый разгар жаркого лета струи аржаанов поражают своей ледяной свежестью. Радоновые воды рекомендуются для наружного применения при лечении болезней периферической нервной системы, сердечно-сосудистой системы и органов движения.`,
                  `Местоположение: в 25 км от с. Бай-Тал Бай-Тайгинского района`,
                ],
                image: { title: "Автор: Светлана Мунзук", url: "asset24.webp" },
              },

              {
                info: [
                  `Аржаан Улаатай – аналогов водам этого аржаана на территории России нет. В народе бытует мнение, что источник помогает при реабилитации после гепатитов, а также при различных экземах и болезнях щитовидной железы.`,
                  `Местоположение: в 20 км к востоку от с. Хандагайты Овюрского района.`,
                ],
                image: { title: "Автор: Кара-Кыс Аракчаа", url: "asset25.webp" },
              },

              {
                info: [
                  `Аржаан Ажыг-Суг. Тувинцы называют его «ижин-баар аржааны», т.е. желудочно-печеночный источник. Волшебный аржаан ученые прозвали местом «паломничества» больных с проблемами органов пищеварения, почек, и даже тех, кто борется с онкологией и кожными заболеваниями. Целебные воды аржаана способствуют очищению организма, выведению шлаков и токсинов.`,
                  `Местоположение: в Чаа-Хольском районе, в горах на левом берегу Саяно-Шушенского водохранилища`,
                ],
                image: { title: "Автор: Кара-Кыс Аракчаа", url: "asset26.webp" },
              },

              {
                info: [
                  `Аржаан Уургайлыг. Этому источнику приписывают поистине чудодейственную силу: нормализует кровяное давление, помогает при остеохондрозах, воспалительных процессах, гипертонии, заболеваниях суставов, мышц, сухожилий, последствиях травматических повреждений и заболеваниях периферической нервной системы.`,
                  `Местоположение: в Тандинском районе Республики Тыва`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset27.webp" },
              },
            ],
          },
        },
      ],
    },
    else: {
      info: [],
      subComponents: [
        {
          type: "byPhoto",
          propsData: {
            data: [
              {
                info: [
                  `Можно познакомиться со староверами`,
                  `Постоянно преследуемые советской властью староверы были вынуждены уходить все выше по течению Енисея. Новые поселения закладывались в долине реки, где был хоть крошечный клочок земли под распашку. Поэтому исторически все поселки оказались нанизаны на нитку Каа-Хема. Именно здесь, в верховьях Малого Енисея, сохранились в первозданном виде быт, уклад и традиции русских староверов.`,
                  `Местоположение: в Каа-Хемском районе Республики Тыва`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset28.webp" },
              },

              {
                info: [
                  `Покормить маралов с руки`,
                  `Одно из обязательных к посещению мест в Туве – мараловодческое хозяйство.`,
                  `Мараловодческая ферма находится в самом центре тувинских предгорий. Здесь же содержится табун лошадей. Экскурсовод познакомит с историей создания этой фермы, отведет к месту сосредоточения маралов. Посетители могут увидеть процесс производства пантов, а в сезон сбора пантов можно принять ванны с пантакрином. Также можно купить бальзамы и настойки, изготовленные здесь же.`,
                  `Местоположение: в 75 км от г. Кызыла`,
                ],
                image: { title: "Автор: Неизвестен", url: "asset29.webp" },
              },
            ],
          },
        },
      ],
    },
    calendar: {
      info: [],
      subComponents: [
        {
          type: "calendar",
          propsData: {},
        },
      ],
    },
  },
};

/*
Туроператоры
1.	ООО «Центр Азии», директор Бянкина Елена Тюлюшевна
Внутренний, въездной и выездной туризм.
Познавательные, экологические, религиозные туры. Организация проведения семинаров, конференций.
г. Кызыл, ул. Красных партизан, 36, 8(39422) 3-23-26
2.	Агентство путешествий «Тоджа Тур», директор Острикова Юлия Вячеславовна
Внутренний, въездной и выездной туризм. Организация активного отдыха и спортивной рыбалки в Тоджинском районе. Туры выходного дня.
г. Кызыл, ул. Титова, 36, 8(39422)3-28-11
3.	ООО «Алаш-Трэвел», директор Кирова Наталья Сергеевна
Внутренний, въездной и выездной туризм. Приключенческие и экстремальные туры (сплав по горным рекам от 1 до 5 категории сложности). Культурно-познавательные; этнографические; тренинговые маршруты по Туве. Альпинизм (до 4 категории сложности). Охота и рыбалка, конные, автомобильные, комбинированные и пешие туры по Туве.
г. Кызыл, ул. Гагарина, 60-12, 8(39422)2-18-50
4.	ООО «ТываАянTravel», директор Хорлуу Айслу Васильевна
Внутренний, въездной и выездной туризм. Культурно-познавательные туры по Туве. Бронирование гостиниц, турбаз по Тыве и Хакасии.
г. Кызыл, ул. Московская,1, 8(39422) 5-64-25
5.	Турфирма «Роза ветров», директор Монгуш Аяс Кириллович
Внутренний, въездной и выездной туризм. Экскурсионные туры по Туве. Пляжный отдых на озерах Тувы.
г. Кызыл, ул. Кочетова, 64, 8(39422) 2-46-23
6.	Турфирма «Тува Тур», директор Салчак Арат Озук-оолович
Внутренний, въездной и выездной туризм. Экскурсионные и тематические маршруты по Туве.
www.tour.tuva24.ru, 8-923-261-1444
7.	Туристическая фирма «Саянское кольцо. Тыва», директор Иванченко Елена Геннадьевна
Внутренний, въездной туризм. Экскурсионные, этнографические, пешие туры.
www.sayanring.ru, 8-983-283-84-18
8.	Турфирма «Тайга», директор Донгак Буян Мерген-оолович
Внутренний, въездной туризм по Монгун-Тайгинскому району.
с. Мугур-Аксы, Республика Тыва, Маадыр-оола, 21, 8-923-540-0988
9.	Туристская фирма «Термел-Тур», директор Сонам-Байыр Темир Шулууевич
Внутренний, въездной туризма. Приключенческие и экстремальные туры.
с. Сарыг-Сеп, ул. Енисейская, 143, тел.: 89232657713
10.	Туристская фирма «Белая дорога», Демидова Юлия Викторовна
Выездной и внутренний туризм.
г. Кызыл, ул. Московская, д. 137А, кабинет 310, 89233835513, 89232135513
11.	Туристская фирма «Мега-Тува», директор Елаева Чейнеш Викторовна
Въездной туризм, детский туризм.
г. Кызыл, ул. Московская, 105, 89133434555
12.	Туристская фирма «Свежий ветер в Туве», директор Керимова Александра Вячеславовна
Внутренний въездной, спортивный туризм. Охотничий и рыболовный туризм на катере.
г. Кызыл, ул. Кочетова, д. 1, 8 (39422) 2-29-26, 89232663849, 892931604444
13.	ООО «Сай-ХонашТрэвел», директор Сарыглар Евгений Викторович
Внутренний туризм. Въездной международный туризм.
г. Кызыл, ул. Горная, 13-84

Как добраться?
Прямые авиарейсы:
Новосибирск – Кызыл – Новосибирск

Улан-Удэ – Кызыл – Улан-Удэ

Красноярск – Кызыл – Красноярск

Москва – Кызыл – Москва

Внизу нарисовать самолетик (как на табло авиарейсов):
Москва                                         5 часов                                   Кызыл

На автомашине:
из Абакана около 6 часов;
из Красноярска около 12 часов.

Размещение
1.	Отель КЫЗЫЛ Гранд 5*
Отель Кызыл предлагает просторные и комфортабельные номера, ресторан европейской и местной кухни и лобби-бар. Универсальное пространство конференц-залов - подходящее место для проведения мероприятий любого типа. Гости отеля могут заниматься в тренажерном зале, также СПА-центр предлагает широкий выбор массажей, крытый бассейн, сауну и хаммам.
г. Кызыл, ул. Чульдум, д. 2

2.	Гостиница Монгулек 4*
Гостиница находится в пяти минутах ходьбы от центра и рядом есть городской парк. Наши комфортные номера со всеми удобствами — бесплатный Wi-Fi и бесплатная парковка на территории.
г. Кызыл, ул. Кочетова, д. 1
3.	Отель Чалама 3*
Это новый, который открылся в 2019 году.  В здании также имеется сувенирный магазин и терраса. В номерах имеется все необходимое для комфортного проживания. Бесплатный Wi-Fi доступен на всей территории отеля.
г. Кызыл, ул. Интернациональная улица, д. 12
4.	Гостиница Одуген 3*
Гостиница располагается в «зеленой зоне» в центре города Кызыла на побережье Енисея. Гостиница названа в честь знаменитой тайги, которая славится не только среди охотников и рыболовов, но даже среди ученых и исследователей, путешественников, художников и писателей.
В каждом номере отдельный балкон, откуда открывается вид на гору «Догээ». К услугам гостей: сауна, ресторан, кафе «Осень», кафе «Лето», также имеется сувенирный киоск, массажный кабинет, косметический кабинет.
г. Кызыл, ул. Красных Партизан, д. 36
5.	Гостиница Буян-Бадыргы 3*
Отель расположен в южной части г. Кызыла, находится в 10 минутах езды от аэропорта.
Отель назван в честь основателя Тувинского государства – Буяна-Бадыргы Хайдып оглу Монгуша.
Гостиничный комплекс предлагает гостям: отдых в комфортабельных номерах. Проведение деловых совещаний, конференций и выставок, организацию банкетов, фуршетов, бизнес-ланчей и праздничных ужинов.
В ресторане гостям предложат, блюда национальной тувинской кухни, а также азиатские и европейские блюда.
г. Кызыл, ул. Московская, д.1

*/
