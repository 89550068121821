export const homeSections: iContent.HomeElements[] = [
  {
    top: "1%",
    left: "0%",
    fontSize: "1.7em",
    rotation: "0",
    strWidth: "12em",
    padding: "1em",
    content: "О Туве",
  },
  {
    top: "11.67%",
    left: "22%",
    fontSize: "1.7em",
    rotation: "0",
    strWidth: "10em",
    padding: "1em",
    content: "Жизнь Тувы",
  },
  {
    top: "35.5%",
    left: "27.5%",
    fontSize: "1.7em",
    rotation: "0",
    strWidth: "10em",
    padding: "1em",
    content: "Открыть Тыву",
  },
  {
    top: "54.6%",
    left: "12.1%",
    fontSize: "1.7em",
    rotation: "180deg",
    strWidth: "10em",
    padding: "0",
    content: "Попробуй Туву на вкус",
  },
  {
    top: "54.6%",
    left: "-12.1%",
    fontSize: "1.45em",
    rotation: "180deg",
    strWidth: "13em",
    padding: "0",
    content: "Президентские каникулы в Туве",
  },
  {
    top: "35.5%",
    left: "-27.5%",
    fontSize: "1.7em",
    rotation: "0",
    strWidth: "10em",
    padding: "1em",
    content: "Люди Тувы",
  },
  {
    top: "11.67%",
    left: "-22%",
    fontSize: "1.7em",
    rotation: "0",
    strWidth: "10em",
    padding: "0",
    content: "Увези Туву с собой!",
  },
];
