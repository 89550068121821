<script lang="ts" setup>
import { PropType, Ref, onBeforeMount, ref } from "vue";
import { formatData, validateData, asd, getDaysName } from "./helpers";

const valid = ref(false);
const showEventStatus = ref(false);
const selectedEvet = ref<{ event: string[]; date: string }>();
const eventList = ref<HTMLDivElement>();

const errorMessage = ref<string[]>([]);
const events: Ref<Map<number, { event: string[] }>> = ref(new Map<number, { event: string[] }>());

const props = defineProps({
  year: {
    type: Number as PropType<number>,
    required: true,
  },
  events: {
    type: Array as PropType<{ datetime: string; event: string; allmonth: boolean }[]>,
    required: true,
  },
});

function parseYearData() {
  const data = [];
  let temp = [];
  for (let i = 0; i < 12; i++) {
    temp.push({
      number: i,
      name: new Date(props.year, i).toLocaleString("ru", { month: "long" }),
      days: asd(i, props.year),
    });
    if (temp.length > 3) {
      data.push(temp);
      temp = [];
    }
  }
  data.push(temp);
  return data;
}

function showEvent({ year, month, day }: { year: number; month: number; day: number }) {
  if (events.value.has(new Date(year, month, day).getTime())) {
    const date = new Date(year, month, day).getTime();
    selectedEvet.value = { event: events.value.get(date)!.event, date: new Date(date).toLocaleString("ru", { dateStyle: "long" }) };
    eventList.value?.classList.add("showModal");
  }
}

function checkEvent({ year, month, day }: { year: number; month: number; day: number }) {
  if (!day) return false;
  const date = new Date(year, month, day).getTime();
  return events.value.has(date);
}

function closeModal() {
  selectedEvet.value = { date: "", event: [] };
  eventList.value?.classList.remove("showModal");
  return;
}

onBeforeMount(() => {
  const result = validateData(props.year, props.events);
  if (result.error) return (errorMessage.value = result.message);
  events.value = formatData(props.events);
  valid.value = true;
});
</script>

<template>
  <div v-if="errorMessage.length">{{ errorMessage }}</div>
  <div v-if="valid" class="year">
    <div v-for="row in parseYearData()" class="year__rows">
      <div v-for="month in row" class="year__month">
        <h3>{{ month.name }}</h3>
        <div class="year__month__nameOfDays">
          <p v-for="(dayByName, index) in getDaysName(year)" :key="index">{{ dayByName }}</p>
        </div>
        <div class="year__month__days" v-for="weeks in month.days">
          <p
            v-for="(day, index) in weeks"
            :key="index"
            :class="{ withEvent: checkEvent({ year: props.year, month: month.number, day: day as number }) }"
            @click="showEvent({ year: props.year, month: month.number, day: day as number })"
          >
            {{ day ? day : "" }}
          </p>
        </div>
      </div>
    </div>
    <div class="showEvent" ref="eventList">
      <div class="showEvent-wrp">
        <input type="button" @click="closeModal" />
        <p v-if="selectedEvet?.date" class="showEvent__date">{{ selectedEvet.date }}</p>
        <p v-for="(event, index) in selectedEvet?.event" :key="index">{{ event }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.year {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  &__rows {
    width: 100%;
    display: flex;
    gap: 2em;
  }
  &__month {
    flex-basis: calc(33% - 4em);
    display: flex;
    flex-direction: column;
    gap: 0.1em;
    justify-content: flex-start;
    & h3 {
      margin: 0;
      font-size: 2em;
      text-transform: uppercase;
      text-align: center;
    }
    &__nameOfDays {
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      gap: 0.1em;
      & p {
        margin: 0;
        background-color: #40e0d0;
        flex-basis: 12%;
        text-transform: uppercase;
      }
    }
    &__days {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 0.1em;
      & p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        aspect-ratio: 1/1;
        flex-basis: 12%;
        text-align: center;
        border-radius: 0.4em;
      }
    }
  }
}
.showEvent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000056;
  &__date {
    text-align: center;
  }
  &-wrp {
    padding: 1em;
    display: flex;
    width: max-content;
    flex-direction: column;
    border: 0.1em solid #40e0d0;
    justify-content: flex-start;
    border-radius: 0.8em;
    background-color: #fff;
    font-size: 1.3em;
    z-index: 1;
    & input[type="button"] {
      width: 1.5em;
      aspect-ratio: 1/1;
      display: block;
      background: transparent url("@/share/assets/icons/close_black.svg") no-repeat center center / contain;
      outline: none;
      align-self: flex-end;
      border: none;
      cursor: pointer;
    }
  }
}
.withEvent {
  background-color: #40e0d0;
  cursor: pointer;
}

.showModal {
  visibility: visible;
  animation: showWindow 0.15s linear forwards;
}

@keyframes showWindow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
