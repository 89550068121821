const sections = {
  "": {
    name: "",
    status: false,
  },
  "О Туве": {
    name: "about",
    status: true,
  },
  "Жизнь Тувы": {
    name: "life",
    status: true,
  },
  "Открыть Тыву": {
    name: "discover",
    status: true,
  },
  "Попробуй Туву на вкус": {
    name: "test",
    status: true,
  },
  "Президентские каникулы в Туве": {
    name: "holidays",
    status: true,
  },
  "Люди Тувы": {
    name: "people",
    status: true,
  },
  "Увези Туву с собой!": {
    name: "take",
    status: true,
  },
};

export function resolveSection(name: iContent.Sections) {
  if (typeof sections[name] === "undefined") return false;
  if (!sections[name].status) return false;
  return sections[name].name;
}
