<script lang="ts" setup>
import { useStore } from "@/entities";
import { computed, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { components } from "@/share/components";
import { MusicInstumental } from "@/widgets";
import { onBeforeRouteUpdate } from "vue-router";

const store = useStore();
const route = useRoute();

const showComponentState = ref(false);
const propData = ref<iContent.MusicInst>();
const { maintext, allphoto } = components;
const photosStatus = ref(false);

const content = computed(() => store.state.content.section.pages[route.params.page as string]);
const byPhotoContent = reactive<{ title: string; info: string[] }>({ title: "", info: [] });

function showComponent(comp?: iContent.MusicInst) {
  if (!comp) return (showComponentState.value = false);
  propData.value = comp;
  showComponentState.value = true;
}

function closePhoto() {
  photosStatus.value = false;
}

onBeforeRouteUpdate((to, from, next) => {
  photosStatus.value = false;
  showComponentState.value = false;
  byPhotoContent.title = "";
  byPhotoContent.info = [];
  propData.value = {} as iContent.MusicInst;
  next();
});
</script>

<template>
  <div class="content" v-if="!showComponentState && !photosStatus">
    <div class="content__title">
      <button v-if="content.photos?.length" @click="photosStatus = true">Фотогалерея</button>
      <h1>{{ $store.state.content.section.section }}</h1>
    </div>
    <maintext
      :text="{ text: content.info, title: content.title }"
      :img="content.image"
      :style="{ align: content.align, direction: content.direction, height: content.height, imageWidth: content.imageWidth, hidden: content.hidden }"
    ></maintext>
    <component v-for="(comp, index) in content.subComponents" :key="index" :is="components[comp.type]" :prop="comp.propsData" @showComponent="showComponent"></component>
  </div>
  <MusicInstumental v-else-if="showComponentState && propData" :prop="propData" @showComponent="showComponent" />
  <allphoto v-if="photosStatus" :prop="{ data: content.photos }" @close="closePhoto" />
</template>

<style lang="scss" scoped>
.content {
  padding: 2em;
  flex-basis: calc(100% - 27.5% - 6em);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1em;
  &__title {
    position: relative;
    & button {
      padding: 0.3em 0.4em;
      position: absolute;
      top: -0.2em;
      left: 0;
      outline: none;
      background-color: transparent;
      border: none;
      font-size: 1.5em;
      font-weight: bold;
      border: 0.1em solid black;
      border-radius: 0.7em;
      cursor: pointer;
    }
    & h1 {
      text-align: center;
      margin: 0;
    }
  }
}
</style>
