import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/pages/HomeView.vue";
import Content from "@/pages/Content.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: { layout: "home" },
    component: HomeView,
  },
  {
    path: "/content/:name/:page",
    name: "Content",
    meta: { layout: "content" },
    component: Content,
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
