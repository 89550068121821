<script lang="ts" setup>
import { PropType, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";

const active = ref(0);
const state = ref(0);
const all = ref(false);

defineProps({
  prop: {
    type: Object as PropType<{ title: string; data: { image: { title: string; url: string }; title: string; info: string[] }[] }>,
    required: true,
  },
});

function showPhoto(index: number) {
  active.value = index;
  state.value = index;
  all.value = true;
}

onBeforeRouteUpdate((to, from, next) => {
  active.value = 0;
  state.value = 0;
  next();
});
</script>

<template>
  <div class="images">
    <div class="images__info" v-if="prop.data[active]">
      <h1 v-if="prop.data[active].title">{{ prop.data[active].title }}</h1>
      <p v-for="(text, index) in prop.data[active].info" :key="index">{{ text }}</p>
    </div>
    <div class="images-wrp">
      <div>
        <img
          v-for="(img, index) in prop.data"
          :key="index"
          :src="require(`@/share/assets/images/${$route.params.name}/${$route.params.page}/${img.image.url}`)"
          alt=""
          :style="{ transform: `translate(calc(-21em * ${active} + 5em)) !important` }"
          :class="{ activePhoto: index === active }"
          @click="showPhoto(index)"
        />
      </div>
      <input type="button" v-if="active > 0" id="left" @click="active = active - 1" />
      <input type="button" v-if="active < prop.data.length - 1" id="right" @click="active = active + 1" />
    </div>
    <p class="images__tips">Листайте дальше, чтобы увидеть больше фотографий. Нажмите на фото, чтобы увеличить на весь экран</p>
    <div class="photo" v-if="all">
      <img :src="require(`@/share/assets/images/${$route.params.name}/${$route.params.page}/${prop.data[state].image.url}`)" alt="img" />
      <p v-if="prop.data[state].image.title?.length > 0">{{ prop.data[state].image.title }}</p>
      <p v-else class="photo-empty"></p>
      <input class="photo__close" @click="all = false" type="button" />
      <input class="photo__left" v-if="state > 0" @click="state = state - 1" type="button" />
      <input class="photo__right" v-if="state < prop.data.length - 1" @click="state = (state + 1) % prop.data.length" type="button" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.images {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__info {
    padding: 0 1em;
    flex-grow: 1;
    height: 15em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
    font-size: 1.5em;
    & h1 {
      margin: 0.2em 0 0.4em 0;
      text-align: center;
    }
    & p {
      margin: 0.2em 0;
    }
    &::-webkit-scrollbar {
      width: 1.5em;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track {
      background-color: #efefef;
      border-radius: 0.8em;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      margin: 10em 0;
      background: #d38202;
      border-radius: 0.8em;
    }
  }
  &-wrp {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & div {
      width: 56em;
      height: 27em;
      display: flex;
      align-items: center;
      overflow: hidden;
      gap: 1em;
      padding-left: 20em;
    }
    & img {
      width: 20em;
      aspect-ratio: 4/3;
      display: block;
      object-fit: contain;
      border-radius: 0.8rem;
      cursor: pointer;
    }
  }
  & input[type="button"] {
    position: absolute;
    top: 50%;
    display: block;
    width: 3em;
    aspect-ratio: 1/1;
    transform: translate(0, -50%);
    border-radius: 50%;
    outline: none;
    border: none;
    cursor: pointer;
  }
  &__tips {
    text-align: center;
    color: rgba(63, 63, 63, 1);
    font-size: 1.15em;
  }
}

#left {
  left: -1.5em;
  background: transparent url("@/share/assets/icons/left.svg") no-repeat center center / contain;
}
#right {
  right: -1.5em;
  background: transparent url("@/share/assets/icons/right.svg") no-repeat center center / contain;
}

.activePhoto {
  width: 30em !important;
  height: 27em !important;
}
.photo {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #0000009a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & * {
    -webkit-tap-highlight-color: transparent;
  }
  & img {
    width: auto;
    max-width: 80vw;
    height: auto;
    max-height: 90vh;
  }
  & p {
    margin: 0.4rem 0 0 0;
    background-color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.7rem;
  }
  & input {
    outline: none !important;
    background-color: transparent;
    border: none;
  }
  &__close {
    position: absolute;
    top: 5% !important;
    right: 5% !important;
    width: 4rem !important;
    aspect-ratio: 1/1;
    background: url("@/share/assets/icons/close.svg") no-repeat center center / cover;
    cursor: pointer;
  }
  &__right {
    position: absolute;
    right: 3%;
    width: 6rem !important;
    aspect-ratio: 1/1;
    background: url("@/share/assets/icons/arrowR.svg") no-repeat center center / contain;
    cursor: pointer;
  }
  &__left {
    position: absolute;
    left: 3%;
    width: 6rem !important;
    aspect-ratio: 1/1;
    background: url("@/share/assets/icons/arrowL.svg") no-repeat center center / contain;
    cursor: pointer;
  }
  &-empty {
    margin: 0.4rem 0 0 0;
    background-color: transparent !important;
    padding: 0.5rem 1rem;
    border-radius: 0.7rem;
  }
}
</style>
