<script lang="ts" setup>
import { useStore } from "@/entities";
import { PropType, onMounted, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";

const props = defineProps({
  prop: {
    type: Object as PropType<{ title: string; data: { title: string; url: string }[] }>,
    required: true,
  },
});

const state = ref(0);
const active = ref(0);
const all = ref(false);

function showPhoto(index: number) {
  state.value = index;
  all.value = true;
}

const initialX = ref<null | number>(0);
const initialY = ref<null | number>(0);

function startTouch(e: TouchEvent) {
  initialX.value = e.changedTouches[0].screenX;
  initialY.value = e.changedTouches[0].screenY;
}

function touchEnd(e: TouchEvent) {
  if (initialX.value === null) {
    return;
  }

  if (initialY.value === null) {
    return;
  }

  const currentX = e.changedTouches[0].screenX;
  const currentY = e.changedTouches[0].screenY;

  const diffX = initialX.value - currentX;
  const diffY = initialY.value - currentY;

  if (Math.abs(diffX) > Math.abs(diffY)) {
    if (diffX > 0) {
      state.value = (state.value + 1) % props.prop.data.length;
    } else {
      state.value = state.value <= 0 ? state.value : state.value - 1;
    }
  }

  initialX.value = null;
  initialY.value = null;
}

onMounted(() => {
  props.prop.data.length > 2 ? (active.value = 1) : (active.value = 0);
});
onBeforeRouteUpdate((to, from, next) => {
  active.value = 0;
  state.value = 0;
  next();
});
</script>

<template>
  <div class="photo-content">
    <div class="photo-content-wrp">
      <div class="photo-content-wrp__images">
        <img
          v-for="(img, index) in prop.data"
          :src="require(`@/share/assets/images/${$route.params.name}/${$route.params.page}/low/${img.url}`)"
          alt="image"
          :style="{ transform: `translate(calc(-21em * ${active} + 5em)) !important` }"
          :class="{ activePhoto: index === active }"
          @click="showPhoto(index)"
        />
      </div>
      <input type="button" v-if="active > 0" id="left" @click="active = active - 1" />
      <input type="button" v-if="active < prop.data.length - 1" id="right" @click="active = active + 1" />
    </div>
    <p>Листайте дальше, чтобы увидеть больше фотографий. Нажмите на фото, чтобы увеличить на весь экран</p>
    <div class="photo" v-if="all">
      <img :src="require(`@/share/assets/images/${$route.params.name}/${$route.params.page}/${prop.data[state].url}`)" alt="img" @touchstart="startTouch" @touchend="touchEnd" />
      <p v-if="prop.data[state].title.length > 0">{{ prop.data[state].title }}</p>
      <p v-else class="photo-empty"></p>
      <input class="photo__close" @click="all = false" type="button" />
      <input class="photo__left" v-if="state > 0" @click="state = state - 1" type="button" />
      <input class="photo__right" v-if="state < prop.data.length - 1" @click="state = (state + 1) % prop.data.length" type="button" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.photo-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  z-index: 4;
  &-wrp {
    position: relative;
    width: 100%;
    &__images {
      width: 57em;
      height: 28em;
      display: flex;
      align-items: center;
      overflow: hidden;
      gap: 1em;
      padding-left: 20em;
      & img {
        width: 20em;
        aspect-ratio: 4/3;
        display: block;
        object-fit: cover;
        border-radius: 0.8rem;
        cursor: pointer;
      }
    }
    & input[type="button"] {
      position: absolute;
      top: 50%;
      display: block;
      width: 3em;
      aspect-ratio: 1/1;
      transform: translate(0, -50%);
      border-radius: 50%;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
  & p {
    text-align: center;
    color: rgba(63, 63, 63, 1);
    font-size: 1.15em;
  }
}
#left {
  left: -1.5em;
  background: transparent url("@/share/assets/icons/left.svg") no-repeat center center / contain;
}
#right {
  right: -1.5em;
  background: transparent url("@/share/assets/icons/right.svg") no-repeat center center / contain;
}
.photo {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #0000009a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & * {
    -webkit-tap-highlight-color: transparent;
  }
  & img {
    width: auto;
    max-width: 80vw;
    height: auto;
    max-height: 90vh;
  }
  & p {
    margin: 0.4rem 0 0 0;
    background-color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.7rem;
  }
  & input {
    outline: none !important;
    background-color: transparent;
    border: none;
  }
  &__close {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 4rem;
    aspect-ratio: 1/1;
    background: url("@/share/assets/icons/close.svg") no-repeat center center / cover;
    cursor: pointer;
  }
  &__right {
    position: absolute;
    right: 3%;
    width: 6rem;
    aspect-ratio: 1/1;
    background: url("@/share/assets/icons/arrowR.svg") no-repeat center center / contain;
    cursor: pointer;
  }
  &__left {
    position: absolute;
    left: 3%;
    width: 6rem;
    aspect-ratio: 1/1;
    background: url("@/share/assets/icons/arrowL.svg") no-repeat center center / contain;
    cursor: pointer;
  }
  &-empty {
    margin: 0.4rem 0 0 0;
    background-color: transparent !important;
    padding: 0.5rem 1rem;
    border-radius: 0.7rem;
  }
}
.activePhoto {
  width: 27em !important;
  height: 27em !important;
}
</style>
