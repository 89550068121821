<script lang="ts" setup>
import Calendar from "./component/Calendar.vue";
import { ref } from "vue";
const events = [
  { datetime: "2023-11-16", allmonth: false, event: "День Сибири на международной выставке «Россия» " },
  { datetime: "2023-11-21", allmonth: false, event: "День Республики Тыва на международной выставке «Россия» " },
  { datetime: "2023-11-22", allmonth: false, event: "Концерт мастеров искусств в рамках Дней культуры Тувы в Москве «Служить искусству — служить России». " },
  { datetime: "2023-12-12", allmonth: false, event: "День Конституции России" },
  { datetime: "2024-02-01", allmonth: true, event: "Национальный праздник «Шагаа» (Новый год по лунному календарю)" },
  { datetime: "2024-05-01", allmonth: false, event: "Национальный турнир по борьбе хуреш" },
  { datetime: "2024-05-06", allmonth: false, event: "День Конституции Республики Тыва" },
  { datetime: "2024-05-09", allmonth: false, event: "Национальный турнир по борьбе хуреш" },
  { datetime: "2024-05-09", allmonth: false, event: "Соревнования по стрельбе из национального лука" },
  { datetime: "2024-05-09", allmonth: false, event: "Соревнования по конным скачкам" },
  { datetime: "2024-05-30", allmonth: false, event: "Обряд освящения горы Бай-Тайга " },
  { datetime: "2024-06-05", allmonth: false, event: "Республиканском фестивале-конкурсе по деревянной резьбе «Уран балды» - «Праздник топора»" },
  { datetime: "2024-06-12", allmonth: false, event: "День России" },
  { datetime: "2024-06-27", allmonth: false, event: "Международный этнический фестиваль «Зов 13 шаманов»" },
  { datetime: "2024-06-27", allmonth: false, event: "Межрегиональный фестиваль этнической моды «По тропинкам к сердцу Тувы»" },
  { datetime: "2024-06-28", allmonth: false, event: "Всероссийские соревнования по спортивной борьбе на Кубок министра обороны Российской Федерации" },
  { datetime: "2024-07-02", allmonth: false, event: "Межрегиональный фестиваль русской культуры на Малом Енисее «ВерховьЕ»" },
  { datetime: "2024-07-05", allmonth: false, event: "Международный фестиваль живой музыки и веры «Устуу-Хурээ»" },
  { datetime: "2024-08-09", allmonth: false, event: "День коренных малочисленных народов Севера" },
  { datetime: "2024-08-13", allmonth: false, event: "Национальный праздник животноводов «Наадым»" },
  { datetime: "2024-08-15", allmonth: false, event: "День Республики Тыва" },
  { datetime: "2024-08-15", allmonth: false, event: "Республиканский праздник войлока в рамках Дня Республики Тыва" },
  { datetime: "2024-08-17", allmonth: false, event: "Международный фестиваль «Хөөмей в Центре Азии»" },
  { datetime: "2024-08-17", allmonth: false, event: "Республиканский конкурс по деревянной резьбе им. Д. Окаанчыка" },
  { datetime: "2024-08-20", allmonth: false, event: "День Российского флага" },
  { datetime: "2024-08-21", allmonth: false, event: "Межрегиональный фестиваль варенья" },
  { datetime: "2024-09-01", allmonth: false, event: "День исторической памяти и чести добровольцев Тувинской Народной Республики" },
  { datetime: "2024-09-10", allmonth: false, event: "День рождения столицы Тувы - Кызыла " },
  { datetime: "2024-09-17", allmonth: false, event: "День флага Республики Тыва" },
  { datetime: "2024-09-30", allmonth: false, event: "День Енисея" },
  { datetime: "2024-10-11", allmonth: false, event: "Добровольное вхождение Тувинской Народной республики в состав СССР" },
  { datetime: "2024-10-23", allmonth: false, event: "Международный день снежного барса" },
  { datetime: "2024-11-01", allmonth: false, event: "День тувинского языка" },
];

const year = ref(2023);
const getCurrentYear = ref(new Date().getFullYear());
</script>
<template>
  <div class="calendar">
    <div class="calendar__year">
      <h1 :class="{ calendar__year__active: year === getCurrentYear }" @click="year = getCurrentYear">{{ getCurrentYear }}</h1>
      <h1 :class="{ calendar__year__active: year === getCurrentYear + 1 }" @click="year = getCurrentYear + 1">{{ getCurrentYear + 1 }}</h1>
    </div>
    <Calendar :events="events" :year="year" />
  </div>
</template>

<style lang="scss">
.calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 1em;
  &__year {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    &__active {
      color: #40e0d0;
      border: 0.1em solid #40e0d0 !important;
    }
  }
  & h1 {
    margin: 0;
    cursor: pointer;
    padding: 0.2em 0.5em;
    border-radius: 0.5em;
    border: 0.1em solid transparent;
  }
}
</style>
