export const take: iContent.Content = {
  section: "Увези Туву с собой!",
  links: [
    {
      title: "Плейлист",
      name: "home",
    },
    {
      title: "Фотообои",
      name: "wallpaper",
    },
    {
      title: "Спонсоры",
      name: "sponsors",
    },
  ],
  pages: {
    home: {
      title: "Плейлист тувинской музыки",
      info: [],
      subComponents: [
        {
          type: "take",
          propsData: {
            image: "asset1.webp",
            data: [
              {
                info: [
                  `А вот и наш обещанный плейлист. Включите его, закройте глаза и вы уже:`,
                  `- скачете на лошади по бескрайней степи;`,
                  `- поднялись на вершину одной из наших прекрасных гор;`,
                  `- идете в тиши бескрайней тувинской тайги, слушая переливы птиц;`,
                  `- видите, как в синеве неба парят орлы;`,
                  `- слышите шум волн могучего Енисея и кристально чистых озер;`,
                  `- чувствуете вкус настоящего тувинского чая с молоком;`,
                  `- любуетесь мириадами звезд через купол юрты.`,
                ],
                image: { url: "" },
              },
            ],
          },
        },
      ],
    },
    wallpaper: {
      title: "Фотообои",
      info: [],
      subComponents: [
        {
          type: "take",
          propsData: {
            image: "asset2.webp",
            data: [
              {
                info: [`Мы собрали для вас фотоподборку обоев для ваших смартфонов`],
                image: { url: "asset3.jpg" },
              },
            ],
          },
        },
      ],
    },
    sponsors: {
      info: [],
      subComponents: [
        {
          type: "video",
          propsData: {
            title: "",
            info: [
              `Энергохолдинг Эн+ в Республике Тыва представлен Тувинской горнорудной компанией (ТГРК). Это ведущее предприятие угледобывающей промышленности региона, основной поставщик топлива и крупнейший экспортер Республики. ТГРК отрабатывает открытым способом два горных участка: разрез «Каа-Хемский» и разрез Чаданский - Улуг Хемского угольного бассейна. Совокупные запасы компании - более 300 миллионов тонн угля исключительно высокого качества.`,
              `В 2023 году благодаря Соглашению о социально-экономическом сотрудничестве между Правительством Республики Тыва и ТГРК была гарантирована экспортная квота в Восточном направлении. Это позволило компании экспортировать максимальный объем топлива за всю историю его работы.`
            ],
            data: [{ url: "video2.mp4", title: "" }],
          },
        },
        {
          type: "photos",
          propsData: {
            title: "",
            data: [
              { title: "", url: "asset2.png" },
              { title: "", url: "asset3.png" },
              { title: "", url: "asset4.png" },
            ],
          },
        },
      ],
    },
  },
};
