<script lang="ts" setup>
import { PropType, ref } from "vue";

const audio = ref<HTMLAudioElement[]>();
const state = ref({
  id: "",
  status: false,
});

defineEmits(["showComponent"]);

defineProps({
  prop: {
    type: Object as PropType<iContent.MusicInst>,
    required: true,
  },
});

function play(inst: string) {
  if (state.value.status) {
    for (let i = 0; i < audio.value!.length; i++) {
      if (audio.value![i].id === state.value.id) {
        audio.value![i].pause();
      }
    }
  }
  for (let i = 0; i < audio.value!.length; i++) {
    if (audio.value![i].id === inst) {
      if (state.value.id === inst) {
        state.value.id = "";
        return audio.value![i].pause();
      } else {
        state.value.id = inst;
        state.value.status = true;
        return audio.value![i].play();
      }
    }
  }
}
</script>

<template>
  <div class="music">
    <h1>{{ prop.title }}</h1>
    <div v-if="prop.info.length">
      <p v-for="(text, index) in prop.info" :key="index">{{ text }}</p>
    </div>
    <div class="music__wrp" :style="`--data-height:${prop.height}`">
      <div class="music__wrp__instruments" v-if="prop.musicList.length">
        <div v-for="(inst, index) in prop.musicList" class="music__wrp__instruments-intr">
          <div>
            <img v-if="inst.image" :src="require('@/share/assets/images/life/hoomei/' + inst.image)" alt="" />
            <p v-if="inst.name">{{ inst.name }}</p>
            <audio :src="require('@/share/assets/images/life/hoomei/mp3/' + inst.url)" ref="audio" :id="`audio${index}`"></audio>
            <input type="button" @click="play(`audio${index}`)" class="music__wrp__instruments-intr-play" :class="{ stop: state.status }" />
          </div>
          <p v-if="inst.description">{{ inst.description }}</p>
        </div>
      </div>
      <div class="music__wrp__images">
        <div v-for="img in prop.images">
          <img :src="require('@/share/assets/images/life/hoomei/' + img.url)" alt="img" :style="`--data-width: ${img.width || '8em'}`" />
          <p>{{ img.title }}</p>
        </div>
      </div>
    </div>
    <input type="button" class="music__close" @click="$emit('showComponent')" />
  </div>
</template>

<style lang="scss" scoped>
.music {
  padding: 2em;
  flex-basis: calc(72.5% - 6em);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1em;
  font-size: 1.3em;
  gap: 1em;
  z-index: 2;
  & h1 {
    margin: 0;
  }
  &__wrp {
    height: var(--data-height);
    display: flex;
    gap: 1em;
    overflow: hidden;
    overflow-y: auto;
    justify-content: space-between;
    &__instruments {
      display: flex;
      flex-direction: column;
      gap: 1em;
      &-intr {
        & div {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 2em;
          & img {
            width: 15%;
          }
          & audio {
            display: block;
          }
        }
        &-play {
          display: block;
          align-self: center;
          width: 3em;
          aspect-ratio: 1/1;
          outline: none;
          border: none;
          background-color: transparent;
          background: url("@/share/assets/icons/right.svg") no-repeat center center / contain;
          cursor: pointer;
        }
      }
      & p {
        align-self: flex-start;
      }
    }
    &__images {
      display: flex;
      flex-direction: column;
      text-align: center;
      & img {
        height: var(--data-width);
        object-fit: cover;
        aspect-ratio: 4/4;
        align-self: center;
      }
    }
  }
  &__close {
    display: block;
    width: 2em;
    aspect-ratio: 1/1;
    position: absolute;
    top: 5%;
    right: 5%;
    border: none;
    background: transparent url("@/share/assets/icons/close_black.svg") no-repeat center center / contain;
  }
}
</style>
