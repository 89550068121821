<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { homeSections, resolveSection } from "@/share/libs";

const router = useRouter();

const _x = ref("34.05%");
const _y = ref("12.9%");

function toContent(name: iContent.Sections) {
  const exist = resolveSection(name);
  if (!exist) return;
  router.push({ name: "Content", params: { name: exist, page: "home" } });
}
</script>
<template>
  <div class="home">
    <div class="home__aside">
      <router-link class="logo" :to="{ name: 'Home' }"></router-link>
      <div class="home__aside__info">
        <h1>Открой для себя республику в самом Центре Азии!</h1>
        <p>Выберите раздел, чтобы узнать подробнее</p>
      </div>
    </div>
    <div class="home__circle">
      <div class="home__circle-wrp">
        <div v-for="(div, index) in homeSections" :style="`--data-pos-y: ${_x}; --data-pos-x: ${_y}; --data-top: ${div.top}; --data-left: ${div.left}; --data-num: ${index}`">
          <p @click="toContent(div.content)">
            <span id="str" :style="`--data-size: ${div.fontSize}; --data-dir: ${div.rotation}; --data-width: ${div.strWidth}; --data-padding: ${div.padding}`">{{ div.content }}</span>
          </p>
        </div>
        <span class="home__circle-wrp__haracha"></span>
      </div>
      <p></p>
    </div>
    <div class="home__mountains"></div>
  </div>
</template>

<style lang="scss" scoped>
.home {
  width: 92%;
  height: calc(100% - 4em);
  margin: 0 auto;
  padding: 2em 0;
  display: flex;
  &__aside {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    z-index: 2;
    &__info {
      margin-top: 8.3em;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      color: rgba(255, 255, 255, 1);
    }
    & h1 {
      font-family: "TT_Travels_Next_Bold", sans-serif;
      font-size: 2.8em;
      letter-spacing: 0.01em;
      line-height: 1.4em;
    }
    & p {
      margin: 2em 0;
      width: 70%;
      font-size: 1.8em;
      font-weight: 400;
    }
  }
  &__mountains {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15em;
    box-sizing: border-box;
    background: url("@/share/assets/images/mountains.svg") no-repeat center;
    background-size: cover;
    z-index: 1;
  }
  &__circle {
    position: absolute;
    top: -9.5em;
    right: 0.5em;
    width: 70%;
    aspect-ratio: 1/1;
    background: rgba(172, 119, 24, 0.5);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    & > p {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 64em;
      aspect-ratio: 1/1;
      border: 0.2em solid rgba(231, 193, 117, 1);
      border-radius: 50%;
      z-index: 1;
    }
    &-wrp {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 2;
      & div {
        position: absolute;
        top: calc(var(--data-top) + var(--data-pos-x));
        left: calc(var(--data-left) + var(--data-pos-y));
        aspect-ratio: 2/1;
        z-index: 2;
        & p {
          margin: 0;
          position: absolute;
          top: 0;
          width: 27em;
          aspect-ratio: 2/1;
          filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0));
          background: url("@/share/assets/icons/ellipse.svg") no-repeat center center / contain;
          transform: rotate(calc(51.42deg * var(--data-num)));
          transition: all 0.1s;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          animation: filter 4s ease-out infinite;
          &:hover {
            transform: rotate(calc(51.42deg * var(--data-num))) scale(1.14) !important;
          }
          &:hover > span {
            transform: rotate(var(--data-dir)) scale(1.14) !important;
          }
        }
      }
      &__haracha {
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
        &::before {
          content: "";
          display: block;
          width: 40em;
          aspect-ratio: 1/1;
          background: url("@/share/assets/icons/haracha.svg") no-repeat center center / cover;
          animation: rotation 60s linear infinite;
        }
      }
    }
  }
}

#str {
  padding-bottom: var(--data-padding);
  display: block;
  width: var(--data-width);
  text-transform: uppercase;
  font-family: "TT_Travels_Next_Bold", sans-serif !important;
  color: rgba(79, 50, 0, 1);
  font-size: var(--data-size);
  text-align: center;
  transform: rotate(var(--data-dir));
}
.logo {
  width: 28.6em;
  aspect-ratio: 20/9.1;
  display: flex;
  flex-wrap: wrap;
  background: url("@/share/assets/icons/logo.svg") no-repeat center / contain;
}

@keyframes filter {
  0% {
    filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0));
  }
  50% {
    filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 1));
  }
  100% {
    filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0));
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
